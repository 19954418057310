import { useMemo } from 'react'

import { ThemeUIStyleObject } from 'theme-ui'

import { Flex, FlexProps, Text } from '@plco-pro/components/atoms/index'
import { EventInviteeStatus } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import theme from '@plco-pro/themes/main'

interface Props extends FlexProps {
  type: EventInviteeStatus
  size?: string
  fontSize?: string
}

export const ChipEventInviteeStatus = ({ type, size = 'md', fontSize, ...rest }: Props) => {
  const { formatMessage } = useI18n()

  const chipInfo = useMemo(() => {
    switch (type) {
      case EventInviteeStatus.PARTICIPATION:
        return {
          bg: theme.colors['green-100'],
          color: theme.colors['success'],
          text: formatMessage({ id: 'scheduler.overlay.attend' }),
        }
      case EventInviteeStatus.NOT_CHECK:
        return {
          bg: theme.colors['grey-200'],
          color: theme.colors['text-secondary'],
          text: formatMessage({ id: 'scheduler.overlay.unchecked' }),
        }
      case EventInviteeStatus.STARTER:
        return {
          bg: theme.colors['primary-500-8'],
          color: theme.colors['primary-300'],
          text: formatMessage({ id: 'match-report.lineup-register-modal.player-status.starting' }),
        }
      case EventInviteeStatus.SUBSTITUTE:
        return {
          bg: theme.colors['primary-500-8'],
          color: theme.colors['primary-300'],
          text: formatMessage({ id: 'match-report.lineup-register-modal.player-status.sub' }),
        }
      case EventInviteeStatus.NO_PLAY:
        return {
          bg: null,
          color: null,
          text: '',
        }
      default:
        return {
          bg: theme.colors['pink-100'],
          color: theme.colors['error'],
          text: formatMessage({ id: 'scheduler.overlay.absent' }),
        }
    }
  }, [type, formatMessage])

  const smStyle: ThemeUIStyleObject = {
    height: '16px',
    borderRadius: '4px',
    px: '5px',
    py: '2px',
    bg: chipInfo.bg,
    alignItems: 'center',
  }

  return (
    <Flex sx={{ flex: 1, justifyContent: 'flex-end' }} {...rest}>
      <Flex
        sx={
          size === 'sm'
            ? smStyle
            : {
                px: 1,
                py: '4px',
                borderRadius: '10px',
                bg: chipInfo.bg,
              }
        }
      >
        <Text
          sx={{
            fontWeight: 700,
            color: chipInfo.color,
            fontSize: fontSize || '12px',
          }}
          variant={'p2'}
        >
          {chipInfo.text}
        </Text>
      </Flex>
    </Flex>
  )
}
