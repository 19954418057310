import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { Fragment } from 'theme-ui/jsx-runtime'

import { Box, Divider, Flex, Spinner, Text } from '@plco-pro/components/atoms'
import { FeedbackContents } from '@plco-pro/components/feedback'
import { CardCommonFeedbackDetail } from '@plco-pro/components/organisms/dialog-feedback/card-common-feedback-detail'
import { CardFeedbackDetail } from '@plco-pro/components/organisms/dialog-feedback/card-feedback-detail'
import { Modal } from '@plco-pro/components/organisms/modal'
import {
  Feedback,
  FeedbackComment,
  FeedbackType,
  FeedbackWorkloadMemoData,
  useDeleteFeedbackCommentsMutation,
  useDeleteFeedbackMutation,
  useFeedbackDetailQuery,
} from '@plco-pro/graphqls/react.generated'
import { useI18n, useNationality, useToast } from '@plco-pro/hooks'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useFeedbackError } from '@plco-pro/hooks/feedback-error'
import { useMoment } from '@plco-pro/hooks/moment'
import { useResponsiveContext } from '@plco-pro/providers/responsive'
import {
  AnalyticsEventType,
  EXCLUDED_FEEDBACK_TYPE,
  ExcludedFeedbackType,
} from '@plco-pro/utils/libs'

import { PlayerSelectedType } from '../dialog-player-select'
import { EmptyFeedbackComments } from './empty-feedback-comments'
import { FeedbackCommentsItem } from './feedback-comments-item'
import { getFeedbackAmplitudeType } from './helper'
import { ModalDeleteComment } from './modal-delete-comment'
import { ModalDeleteFeedback } from './modal-delete-feedback'
import { TextAreaComments } from './textarea-comments'

interface Props {
  entry: 'squad' | 'notification' | 'newdashboard'
  isOpen: boolean
  feedbackId: string
  commentId?: string
  onClose: () => void
}

export const DialogFeedbackDetail = ({ entry, isOpen, feedbackId, commentId, onClose }: Props) => {
  const { formatMessage: f } = useI18n()
  const { showToast } = useToast()
  const moment = useMoment()
  const nationality = useNationality()
  const { smAndDown, xlOnly } = useResponsiveContext()
  const showFeedbackError = useFeedbackError()
  const { trackEvent } = useAnalytics()

  const [isOpenDeleteFeedbackModal, setIsOpenDeleteFeedbackModal] = useState(false)
  const [isOpenDeleteCommentModal, setIsOpenDeleteCommentModal] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editdId, setEditId] = useState('')
  const [isInitQuery, setIsInitQuery] = useState(false)

  const { data, loading } = useFeedbackDetailQuery({
    variables: {
      id: feedbackId,
      multilingualTextInput: {
        nationality,
      },
    },
    onCompleted: ({ feedback }) => {
      const workloadMemoData = feedback.contentsData as FeedbackWorkloadMemoData

      const feedbackTypeText =
        feedback.type === FeedbackType.WORKLOAD_MEMO
          ? `${workloadMemoData.eventData?.type.toLowerCase() || 'workout'}_memo`
          : EXCLUDED_FEEDBACK_TYPE[feedback.type as ExcludedFeedbackType]

      trackEvent(AnalyticsEventType.VIEW_FEEDBACK, {
        type: feedbackTypeText,
        conversion: entry,
      })

      if (commentId && !isInitQuery) {
        // 댓글이 존재하는지 체크
        const isIncluded = feedback.commentList.findLast((comment) => comment.id === commentId)

        if (!isIncluded) {
          showToast(f({ id: 'notification.comment-deleted' }), { status: 'error' })
        }
      }

      if (!isInitQuery) {
        setIsInitQuery(true)
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorCode = graphQLErrors[0].extensions.errorCode

      if (errorCode === 'FEEDBACK_NOT_FOUND') {
        toast.error(f({ id: 'notification.feedback-deleted' }))
        onClose()

        return
      }

      showFeedbackError(graphQLErrors, true)
    },
  })

  const [deleteFeedbackMutation, { loading: deleteFeedbackLoading }] = useDeleteFeedbackMutation({
    onCompleted: () => {
      const workloadMemoData = data?.feedback.contentsData as FeedbackWorkloadMemoData
      const feedbackTypeText =
        data?.feedback.type === FeedbackType.WORKLOAD_MEMO
          ? `${workloadMemoData.eventData?.type.toLowerCase() || 'workout'}_memo`
          : EXCLUDED_FEEDBACK_TYPE[data?.feedback.type as ExcludedFeedbackType]

      trackEvent(AnalyticsEventType.DELETE_FEEDBACK, {
        type: feedbackTypeText,
      })
      showToast(f({ id: 'feedback.feedback-has-deleted' }), { status: 'success' })
      onClose()
    },
    onError: ({ graphQLErrors }) => {
      showFeedbackError(graphQLErrors, true)
    },
    update: (...updateArgs) => {
      const cache = updateArgs[0]

      if (data?.feedback) {
        cache.evict({ id: cache.identify(data?.feedback), broadcast: false })
        cache.gc()
      }
    },
  })

  const [deleteCommentMutation, { loading: deleteCommentLoading }] =
    useDeleteFeedbackCommentsMutation({
      onCompleted: () => {
        if (data?.feedback) {
          const feedbackTypeText = getFeedbackAmplitudeType(data?.feedback)

          trackEvent(AnalyticsEventType.DELETE_FEEDBACK_COMMENT, {
            type: feedbackTypeText,
          })
        }
        setEditId('')
        setIsOpenDeleteCommentModal(false)
      },
      onError: ({ graphQLErrors }) => {
        showFeedbackError(graphQLErrors, true)
      },
    })

  const hasFeedbackContents = useMemo(() => {
    return (
      data?.feedback.type !== FeedbackType.REQUEST && data?.feedback.type !== FeedbackType.NORMAL
    )
  }, [data?.feedback.type])

  const onDeleteFeedback = () => {
    deleteFeedbackMutation({
      variables: {
        id: feedbackId,
      },
    })
  }

  const onDeleteComment = () => {
    deleteCommentMutation({
      variables: {
        input: {
          feedbackId,
          commentId: editdId,
        },
        multilingualTextInput: {
          nationality,
        },
      },
    })
  }

  const onClickEditIcon = (id?: string) => {
    setEditId(id || '')
    setIsEditing(!isEditing)
  }

  const onClickCommentDeleteIcon = (commentId: string) => {
    setEditId(commentId)
    setIsOpenDeleteCommentModal(true)
  }

  const modalMaxHeight = useMemo(() => {
    const viewportHeight = window.innerHeight

    if (smAndDown) {
      return '100%'
    }

    if (xlOnly) {
      return viewportHeight > 800 ? '800px' : `${viewportHeight - 40}px`
    }

    return viewportHeight > 590 ? '590px' : `${viewportHeight - 40}px`
  }, [smAndDown, xlOnly])

  return (
    <Modal
      isFullScreen={smAndDown}
      open={isOpen}
      width={'560px'}
      maxHeight={modalMaxHeight}
      contentPaddingDisable={true}
      onClose={onClose}
      focusTrapped={false}
      headerProps={{
        title: f({ id: 'feedback.view-feedback-details' }),
        sx: { fontSize: 's1' },
      }}
    >
      {data && !loading ? (
        <Fragment>
          {hasFeedbackContents ? (
            <Box sx={{ width: '100%', pt: 3, pb: 4, bg: 'grey-50' }}>
              <FeedbackContents
                date={moment(data?.feedback.createdAt)}
                player={data?.feedback.player as PlayerSelectedType}
                feedback={data?.feedback}
                feedbackContentsType={'detail'}
              />

              <CardFeedbackDetail
                editId={editdId}
                feedback={data?.feedback as Feedback}
                isEditing={isEditing}
                onEdit={onClickEditIcon}
                onDelete={() => setIsOpenDeleteFeedbackModal(true)}
              />
            </Box>
          ) : (
            <CardCommonFeedbackDetail
              editId={editdId}
              feedback={data.feedback as Feedback}
              isEditing={isEditing}
              onEdit={onClickEditIcon}
              onDelete={() => setIsOpenDeleteFeedbackModal(true)}
            />
          )}

          <Flex sx={{ flexDirection: 'column', gap: 4, pt: 4, pb: 3 }}>
            <Flex sx={{ gap: 1, px: [2, 3] }}>
              <Text sx={{ fontSize: 'h5', fontWeight: 700 }}>{f({ id: 'feedback.comments' })}</Text>

              <Text sx={{ fontSize: 'h5', fontWeight: 700 }}>
                {data?.feedback.commentList.length || ''}
              </Text>
            </Flex>
            {data?.feedback.commentList.length ? (
              <Flex sx={{ flexDirection: 'column' }}>
                {data.feedback.commentList.map((comment, index) => {
                  if (index) {
                    return (
                      <Fragment key={comment.id}>
                        <Divider sx={{ my: '20px' }} />

                        <FeedbackCommentsItem
                          feedback={data.feedback}
                          feedbackId={feedbackId}
                          editId={editdId}
                          comment={comment as FeedbackComment}
                          isEditing={isEditing}
                          onEdit={onClickEditIcon}
                          onDelete={onClickCommentDeleteIcon}
                        />
                      </Fragment>
                    )
                  }

                  return (
                    <FeedbackCommentsItem
                      key={comment.id}
                      feedback={data.feedback}
                      feedbackId={feedbackId}
                      editId={editdId}
                      comment={comment as FeedbackComment}
                      isEditing={isEditing}
                      onEdit={onClickEditIcon}
                      onDelete={onClickCommentDeleteIcon}
                    />
                  )
                })}
              </Flex>
            ) : (
              <EmptyFeedbackComments />
            )}

            <TextAreaComments feedbackId={feedbackId} isEditing={isEditing} />
          </Flex>

          <ModalDeleteFeedback
            loading={deleteFeedbackLoading}
            isOpen={isOpenDeleteFeedbackModal}
            onClose={() => setIsOpenDeleteFeedbackModal(false)}
            onDelete={onDeleteFeedback}
          />

          <ModalDeleteComment
            loading={deleteCommentLoading}
            isOpen={isOpenDeleteCommentModal}
            onClose={() => setIsOpenDeleteCommentModal(false)}
            onDelete={onDeleteComment}
          />
        </Fragment>
      ) : (
        <Flex
          sx={{ flex: 1, height: '100%', py: 4, justifyContent: 'center', alignItems: 'center' }}
        >
          <Spinner />
        </Flex>
      )}
    </Modal>
  )
}
