import {
  FSportsTeamFragment,
  FSportsTeamInvitationRequestFragment,
  FSportsTeamUserMapFragment,
  SportsTeamInvitationRequestStatus,
} from '@plco-pro/graphqls/react.generated'

class ViewerTeam {
  private createTeamMaps = (
    teamUserMap: FSportsTeamUserMapFragment[],
    invitationRequests: FSportsTeamInvitationRequestFragment[],
  ): FSportsTeamFragment[] => {
    const teamMapsTeams = teamUserMap.map((teamMap) => teamMap.team)
    const invitationRequestsTeams = invitationRequests
      .filter(
        (invitationRequest) =>
          invitationRequest.status !== SportsTeamInvitationRequestStatus.LEFT &&
          invitationRequest.status !== SportsTeamInvitationRequestStatus.REJECTED,
      )
      .map((invitationRequest) => invitationRequest.team)

    return [...(teamMapsTeams || []), ...(invitationRequestsTeams || [])]
  }

  private getCurrentActiveTeam = (activeTeamId: string, teamMaps: FSportsTeamFragment[]) => {
    const currentActiveTeam = teamMaps.find((team) => team?.id === activeTeamId) || teamMaps[0]

    return currentActiveTeam
  }

  private getActiveInvitationRequestsTeam = (
    invitationRequests: FSportsTeamInvitationRequestFragment[],
    currentActiveTeam: FSportsTeamFragment,
  ) => {
    const activeInvitationRequestsTeam = invitationRequests.find(
      (invitationRequest) => invitationRequest.team?.id === currentActiveTeam?.id,
    )

    return activeInvitationRequestsTeam
  }

  private getTeamAuthority = (
    currentActiveTeam: FSportsTeamFragment,
    teamUserMap: FSportsTeamUserMapFragment[],
    activeInvitationRequestsTeam?: FSportsTeamInvitationRequestFragment,
  ) => {
    const activeTeamMapsTeamAuthority = teamUserMap.find(
      (teamMap) => teamMap.team.id === currentActiveTeam.id,
    )?.teamAuthority

    const activeInvitationRequestsTeamAuthority = activeInvitationRequestsTeam?.teamAuthority
    const activeTeamAuthority = activeTeamMapsTeamAuthority || activeInvitationRequestsTeamAuthority

    return activeTeamAuthority
  }

  public teamMaps = (
    teamUserMap?: FSportsTeamUserMapFragment[],
    invitationRequests?: FSportsTeamInvitationRequestFragment[],
  ) => {
    if (!teamUserMap || !invitationRequests) return []

    return this.createTeamMaps(teamUserMap, invitationRequests)
  }

  public currentActiveTeam = (
    activeTeamId?: string | null,
    teamUserMap?: FSportsTeamUserMapFragment[],
    invitationRequests?: FSportsTeamInvitationRequestFragment[],
  ) => {
    if (!activeTeamId || !teamUserMap || !invitationRequests) {
      return null
    }

    const invitationRequestTeams = invitationRequests.filter(
      (invitationRequest) =>
        invitationRequest.status !== SportsTeamInvitationRequestStatus.LEFT &&
        invitationRequest.status !== SportsTeamInvitationRequestStatus.REJECTED,
    )

    const teamMaps = this.createTeamMaps(teamUserMap, invitationRequestTeams)

    return this.getCurrentActiveTeam(activeTeamId, teamMaps)
  }

  public teamAuthority = (
    activeTeamId?: string | null,
    teamUserMap?: FSportsTeamUserMapFragment[],
    invitationRequests?: FSportsTeamInvitationRequestFragment[],
  ) => {
    if (!activeTeamId || !teamUserMap || !invitationRequests) return null

    const teamMaps = this.createTeamMaps(teamUserMap, invitationRequests)
    const currentActiveTeam = this.getCurrentActiveTeam(activeTeamId, teamMaps)
    const activeInvitationRequestsTeam = this.getActiveInvitationRequestsTeam(
      invitationRequests,
      currentActiveTeam,
    )

    return this.getTeamAuthority(currentActiveTeam, teamUserMap, activeInvitationRequestsTeam)
  }

  public teamInvitationStatus = (
    activeTeamId?: string | null,
    teamUserMap?: FSportsTeamUserMapFragment[],
    invitationRequests?: FSportsTeamInvitationRequestFragment[],
  ) => {
    if (!activeTeamId || !teamUserMap || !invitationRequests) return null

    const teamMaps = this.createTeamMaps(teamUserMap, invitationRequests)
    const currentActiveTeam = this.getCurrentActiveTeam(activeTeamId, teamMaps)
    const activeInvitationRequestsTeam = this.getActiveInvitationRequestsTeam(
      invitationRequests,
      currentActiveTeam,
    )

    return activeInvitationRequestsTeam?.status || null
  }

  public isInvitationPending = (
    activeTeamId?: string | null,
    teamUserMap?: FSportsTeamUserMapFragment[],
    invitationRequests?: FSportsTeamInvitationRequestFragment[],
  ) => {
    if (!activeTeamId || !teamUserMap || !invitationRequests) return false

    const teamMaps = this.createTeamMaps(teamUserMap, invitationRequests)
    const currentActiveTeam = this.getCurrentActiveTeam(activeTeamId, teamMaps)
    const activeInvitationRequestsTeam = this.getActiveInvitationRequestsTeam(
      invitationRequests,
      currentActiveTeam,
    )

    return activeInvitationRequestsTeam?.status === SportsTeamInvitationRequestStatus.PENDING
  }
}

export const viewerTeam = new ViewerTeam()
