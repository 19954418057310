import React, { useEffect } from 'react'

import { useRouter } from 'next/router'

import { Flex } from '@plco-pro/components/atoms/flex'
import { Spinner } from '@plco-pro/components/atoms/spinner'
import { useAuthorizationContext } from '@plco-pro/providers/authorization'

export type AuthorizationProps = {}

export const Authorization: React.FunctionComponent<AuthorizationProps> = ({ children }) => {
  const router = useRouter()
  const { authorized } = useAuthorizationContext()

  const isUnauthorizedPage = router.asPath.match('/unauthorized') !== null
  useEffect(() => {
    if (authorized === false && !isUnauthorizedPage) {
      // redirect to unauthorized page
      router.push('/unauthorized')
    }
  }, [authorized, isUnauthorizedPage, router])

  if (!authorized) {
    // loading screen
    return (
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </Flex>
    )
  } else if (authorized === true || isUnauthorizedPage) {
    // content
    return <>{children}</>
  } else {
    // will be redirected
    return null
  }
}
