import { useKeycloak } from '@react-keycloak/ssr'

import { viewerTeam } from '@plco-pro/domain/viewer-team'
import {
  FSportsTeamInvitationRequestFragment,
  useViewerQuery,
} from '@plco-pro/graphqls/react.generated'
import { useStore } from '@plco-pro/stores'

export const useViewer = () => {
  const { navigation } = useStore()
  const { initialized } = useKeycloak()
  const { activeTeamId } = navigation

  const { data, loading, refetch } = useViewerQuery({
    skip: !initialized,
  })

  const teamUserMap = data?.sports.viewer?.teamMaps
  const invitationRequests = data?.sports.viewer
    ?.invitationRequests as FSportsTeamInvitationRequestFragment[]

  const teamMaps = viewerTeam.teamMaps(teamUserMap, invitationRequests)

  const currentActiveTeam = viewerTeam.currentActiveTeam(
    activeTeamId,
    teamUserMap,
    invitationRequests,
  )

  const teamAuthority = viewerTeam.teamAuthority(activeTeamId, teamUserMap, invitationRequests)

  const teamInvitationStatus = viewerTeam.teamInvitationStatus(
    activeTeamId,
    teamUserMap,
    invitationRequests,
  )

  const isInvitationPending = viewerTeam.isInvitationPending(
    activeTeamId,
    teamUserMap,
    invitationRequests,
  )

  return {
    viewer: data?.sports.viewer,
    teamMaps,
    invitationRequests,
    currentActiveTeam,
    teamAuthority,
    teamInvitationStatus,
    isInvitationPending,
    loading,
    refetch,
  }
}
