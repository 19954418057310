import { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'

import { trimText } from '@common/utils/text'

import { Flex, Text, Button, Icon, Input } from '@plco-pro/components/atoms'
import { SegmentInput } from '@plco-pro/components/molecules'
import { ChipInjury } from '@plco-pro/components/molecules/chip-injury'
import { InjuryFileItem } from '@plco-pro/components/molecules/injury-file-item'
import { LabelInjury } from '@plco-pro/components/molecules/label-injury'
import { SectionInjuryStatus } from '@plco-pro/components/molecules/section-injury-status'
import { TextEditable } from '@plco-pro/components/organisms/card-match-report-lineup/text-editable'
import { InjuryDummy } from '@plco-pro/components/organisms/injury-dummy'
import { Modal } from '@plco-pro/components/organisms/modal'
import { ModalFileLoading } from '@plco-pro/components/organisms/modal-file-loading'
import { ModalInjuryCancel } from '@plco-pro/components/organisms/modal-injury-cancel'
import { OverlayCalendar } from '@plco-pro/components/organisms/overlay-calendar'
import {
  Injury,
  InjuryHistoryStatus,
  InjuryReturnStatus,
  InjuryStatus,
  UserCurrentInjuryListDocument,
  UserPastInjuryListDocument,
  useCreateInjuryMutation,
  useUpdateInjuryMutation,
  DashboardTeamCurrentInjuryUserListDocument,
  SportsBodyPart,
} from '@plco-pro/graphqls/react.generated'
import { useNationality, useToast } from '@plco-pro/hooks'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMoment } from '@plco-pro/hooks/moment'
import ResponsiveContextProvider, { getScreenSize } from '@plco-pro/providers/responsive'
import ThemeProvider from '@plco-pro/providers/theme'
import { useStore } from '@plco-pro/stores'
import { AnalyticsEventType } from '@plco-pro/utils/libs'
import { InjuryType } from '@plco-pro/utils/libs/analytics.types'

const MAX_FILE_SIZE = 10 * 1024 * 1024

enum InjurySegmentType {
  FRONT = 'front',
  BACK = 'back',
}

interface Props {
  teamId: string
  userId: string
  open: boolean
  gender: 'man' | 'woman'
  injuryData?: Injury
  onClose: () => void
  setInjuryData: Dispatch<SetStateAction<Injury | undefined>>
  disableInjuryDataRefetch?: boolean
  onClickLeftButton?: () => void
}

export const ModalInjury = ({
  teamId,
  userId,
  open,
  gender,
  injuryData,
  onClose,
  setInjuryData,
  disableInjuryDataRefetch,
  onClickLeftButton,
}: Props) => {
  const { formatMessage } = useI18n()
  const { smAndDown, mdAndDown, xlAndUp } = getScreenSize(false)
  const moment = useMoment()
  const { showToast } = useToast()
  const { trackEvent } = useAnalytics()
  const nationality = useNationality()
  const { navigation } = useStore()

  const reasonRef = useRef<HTMLTextAreaElement>(null)
  const diagnosisRef = useRef<HTMLTextAreaElement>(null)
  const medicalRecordRef = useRef<HTMLTextAreaElement>(null)
  const returnInfoRef = useRef<HTMLTextAreaElement>(null)
  const fileRef = useRef<HTMLInputElement>(null)

  const [segmentInputType, setSegmentInputType] = useState<InjurySegmentType>(
    InjurySegmentType.FRONT,
  )
  const [bodyPart, setBodyPart] = useState<SportsBodyPart>()
  const [occurDate, setOccurDate] = useState('')
  const [historyStatus, setHistoryStatus] = useState<InjuryHistoryStatus>()
  const [reason, setReason] = useState('')
  const [diagnosis, setDiagnosis] = useState('')
  const [medicalRecord, setMedicalRecord] = useState('')
  const [returnDate, setReturnDate] = useState('')
  const [returnStatus, setReturnStatus] = useState<InjuryReturnStatus>()
  const [returnInfo, setReturnInfo] = useState('')
  const [injuryStatus, setInjuryStatus] = useState<InjuryStatus>()
  const [fileList, setFileList] = useState<any[]>([])
  const [bodyPartError, setBodyPartError] = useState(false)

  const [openRegisterCancelModal, setOpenRegisterCancelModal] = useState(false)
  const [openEditCancelModal, setOpenEditCancelModal] = useState(false)

  const registerInjuryEvent = () => {
    trackEvent(AnalyticsEventType.REGISTER_INJURY, {
      injury_type: injuryStatus === InjuryStatus.IN_PROGRESS ? InjuryType.current : InjuryType.past,
      file_attached: !!fileList.length,
      conversion: navigation.pageViewProperty.conversion,
    })
  }

  const refetchQueries = [
    {
      query: DashboardTeamCurrentInjuryUserListDocument,
      variables: {
        teamId,
        input: {
          nationality,
        },
      },
    },
    {
      query: UserCurrentInjuryListDocument,
      variables: {
        teamId,
        userId,
        input: {
          nationality,
        },
      },
    },
    {
      query: UserPastInjuryListDocument,
      variables: {
        teamId,
        userId,
        input: {
          nationality,
        },
      },
    },
  ]

  const [createInjuryMutation, { loading: createLoading }] = useCreateInjuryMutation({
    context: {
      headers: {
        'Content-Type': 'application/json',
        'apollo-require-preflight': true,
      },
    },
    onCompleted: () => {
      registerInjuryEvent()
      showToast(formatMessage({ id: 'toast.injury-create.success' }), { status: 'success' })
      onClose()
    },
    refetchQueries,
  })

  const [updateInjuryMutation, { loading: updateLoading }] = useUpdateInjuryMutation({
    context: {
      headers: {
        'Content-Type': 'application/json',
        'apollo-require-preflight': true,
      },
    },
    onCompleted: (data) => {
      const injury = data.updateInjury as Injury

      setInjuryData(injury)

      registerInjuryEvent()
      showToast(formatMessage({ id: 'toast.injury-update.success' }), { status: 'success' })
      onClose()
    },
    refetchQueries,
  })

  const onSave = () => {
    if (!bodyPart) {
      setBodyPartError(true)

      return
    }

    if (!historyStatus || !returnStatus || !injuryStatus) {
      return
    }

    setBodyPartError(false)

    const input = {
      teamId,
      userId,
      bodyPart: bodyPart.id,
      occurDate,
      historyStatus,
      reason,
      diagnosis,
      medicalRecord,
      return: {
        date: returnDate,
        status: returnStatus,
        info: returnInfo,
      },
      status: injuryStatus,
    }

    if (!injuryData?.id) {
      createInjuryMutation({
        variables: {
          multilingualTextInput: {
            nationality,
          },
          input,
          files: fileList as never[],
        },
      })
      return
    }

    const maintainFileIdList = fileList.filter((file) => file.id).map((data) => data.id)
    const newFileList = fileList.filter((file) => !file.id)

    updateInjuryMutation({
      variables: {
        multilingualTextInput: {
          nationality,
        },
        input: {
          ...input,
          id: injuryData.id,
          maintainFileIdList,
        },
        files: newFileList as never[],
      },
    })
  }

  const resizeAction = (ref: HTMLTextAreaElement | null) => {
    if (!ref) {
      return
    }

    ref.style.height = '0px'
    ref.style.height = `${ref.scrollHeight}px`
  }

  const openCloseModal = () => {
    if (injuryData?.id) {
      setOpenEditCancelModal(true)

      return
    }

    setOpenRegisterCancelModal(true)
  }

  const onCloseOpenModal = () => {
    if (injuryData?.id) {
      setOpenEditCancelModal(false)
      onClose()

      return
    }

    setOpenRegisterCancelModal(false)
    onClose()
  }

  const onClickBodyPart = (part: SportsBodyPart) => {
    setBodyPart(part)
    setBodyPartError(false)
  }

  const onToggleHistoryStatus = () => {
    if (historyStatus === InjuryHistoryStatus.FIRST) {
      setHistoryStatus(InjuryHistoryStatus.RELAPSE)

      return
    }

    setHistoryStatus(InjuryHistoryStatus.FIRST)
  }

  const onChange = (text: string, callback: (value: SetStateAction<string>) => void) => {
    callback((prev) => {
      if (text.includes('\n')) {
        return prev
      }

      const trimmedText = trimText(text, 100)

      return trimmedText
    })
  }

  const onToggleInjuryStatus = () => {
    if (injuryStatus === InjuryStatus.IN_PROGRESS) {
      setInjuryStatus(InjuryStatus.END)

      return
    }

    setInjuryStatus(InjuryStatus.IN_PROGRESS)
  }

  const onToggleReturnStatus = () => {
    if (returnStatus === InjuryReturnStatus.DECIDED) {
      setReturnStatus(InjuryReturnStatus.UNDECIDED)

      return
    }

    setReturnStatus(InjuryReturnStatus.DECIDED)
  }

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }

    const totalFileLength = e.target.files.length + fileList.length

    if (totalFileLength > 3) {
      showToast(formatMessage({ id: 'toast.injury-file-upload.count.error' }), { status: 'error' })

      return
    }

    const invalidFile = Object.values(e.target.files).find((file) => {
      return file.size > MAX_FILE_SIZE
    })

    if (invalidFile) {
      showToast(formatMessage({ id: 'toast.injury-file-upload.size.error' }), { status: 'error' })

      return
    }

    setFileList([...fileList, ...Array.from(e.target.files)])
  }

  const onAttachFile = () => {
    fileRef.current?.click()
  }

  const onDeleteFile = (id: number) => {
    const newFileList = fileList.filter((_, index) => index !== id)

    setFileList(newFileList)
  }

  const injuryPartMap = useMemo(() => {
    if (bodyPartError) {
      return {
        color: 'text-error',
        bg: '',
      }
    }

    if (bodyPart && injuryStatus === InjuryStatus.IN_PROGRESS) {
      return {
        color: 'primary-500',
        bg: 'primary-100',
      }
    }

    if (bodyPart && injuryStatus === InjuryStatus.END) {
      return {
        color: 'grey-500-80',
        bg: 'grey-500-8',
      }
    }

    return {
      color: 'text-secondary',
      bg: '',
    }
  }, [bodyPartError, bodyPart, injuryStatus])

  useEffect(() => {
    if (!open || disableInjuryDataRefetch) {
      return
    }

    const today = moment().startOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS)

    const returnDate = injuryData?.id ? injuryData?.return.date || injuryData?.occurDate : today

    setBodyPart(injuryData?.bodyPart || undefined)
    setBodyPartError(false)
    setOccurDate(injuryData?.occurDate || today)
    setHistoryStatus(injuryData?.historyStatus || InjuryHistoryStatus.FIRST)
    setReason(injuryData?.reason || '')
    setDiagnosis(injuryData?.diagnosis || '')
    setMedicalRecord(injuryData?.medicalRecord || '')
    setReturnDate(returnDate as string)
    setReturnStatus(injuryData?.return.status || InjuryReturnStatus.DECIDED)
    setReturnInfo(injuryData?.return.info || '')
    setInjuryStatus(injuryData?.status || InjuryStatus.IN_PROGRESS)
    setFileList(injuryData?.fileList || [])
  }, [disableInjuryDataRefetch, injuryData, moment, open])

  const strideInputItems = [
    {
      value: 'front',
      onClick: () => setSegmentInputType(InjurySegmentType.FRONT),
      children: (
        <Flex sx={{ gap: '4px', alignItems: 'center', justifyContent: 'center', width: '78px' }}>
          <Text
            color={segmentInputType === InjurySegmentType.FRONT ? undefined : 'grey-400'}
            sx={{ fontWeight: '500' }}
          >
            {formatMessage({ id: 'player.modal-injury.injury-dummy.front' })}
          </Text>
        </Flex>
      ),
    },
    {
      value: 'back',
      onClick: () => setSegmentInputType(InjurySegmentType.BACK),
      children: (
        <Flex sx={{ gap: '4px', alignItems: 'center', justifyContent: 'center', width: '78px' }}>
          <Text
            color={segmentInputType === InjurySegmentType.BACK ? undefined : 'grey-400'}
            sx={{ fontWeight: '500' }}
          >
            {formatMessage({ id: 'player.modal-injury.injury-dummy.back' })}
          </Text>
        </Flex>
      ),
    },
  ]

  const hasUploadFile =
    (createLoading && !!fileList.length) ||
    (updateLoading && !!fileList.filter((file) => !file.id).length)

  return (
    <ThemeProvider isPageModal={false}>
      <ResponsiveContextProvider isPageModal={false}>
        <Modal
          width={xlAndUp ? '1024px' : '960px'}
          responsiveWidth={xlAndUp ? '1024px' : '960px'}
          height={xlAndUp ? '800px' : '720px'}
          headerProps={{
            title: formatMessage({
              id: `player.modal-injury.${injuryData?.id ? 'edit' : 'register'}.header`,
            }),
          }}
          footerProps={{
            leftButtonProps: {
              children: formatMessage({ id: onClickLeftButton ? 'BACK' : 'CANCEL' }),
              onClick: onClickLeftButton || openCloseModal,
            },
            rightButtonProps: {
              children: formatMessage({ id: 'SAVE' }),
              variant: 'primary',
              onClick: onSave,
            },
          }}
          isFullScreen={mdAndDown}
          closeOnEsc={false}
          open={open}
          onClose={openCloseModal}
          contentPaddingDisable={true}
          focusTrapped={false}
        >
          <Flex
            sx={{
              flexDirection: ['column', 'column', 'row'],
              gap: [5, 5, 0],
              height: !mdAndDown && '100%',
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                gap: [3, 3, 2, '20px'],
                flex: 1,
                pt: [3, 3, 2, 2],
                px: [2, 4, 3],
              }}
            >
              <Flex sx={{ flexDirection: 'column', gap: 1, width: '100%' }}>
                <Text sx={{ fontWeight: 700 }} variant={'h4'} ellipsis>
                  {`${formatMessage({ id: 'player.modal-injury.selection.part.text' })}*`}
                </Text>
                <Flex sx={{ gap: 1, alignItems: 'flex-start' }}>
                  {bodyPart && (
                    <Flex
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        flexShrink: 0,
                        height: '100%',
                      }}
                    >
                      <Icon src={'/images/ic-check.svg'} size={20} color={injuryPartMap.color} />
                    </Flex>
                  )}
                  <Text
                    sx={{ fontWeight: 700 }}
                    variant={'s2'}
                    color={injuryPartMap.color}
                    ellipsis={smAndDown ? false : true}
                  >
                    {bodyPart
                      ? bodyPart.value
                      : formatMessage({ id: 'player.modal-injury.selection.part.none.subtext' })}
                  </Text>
                  {bodyPart && (
                    <Flex sx={{ alignItems: 'center', flexShrink: 0, height: '100%' }}>
                      <ChipInjury injuryStatus={injuryStatus} />
                    </Flex>
                  )}
                </Flex>
              </Flex>
              {smAndDown ? (
                <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: 3 }}>
                  <Flex sx={{ width: '160px' }}>
                    <SegmentInput items={strideInputItems} value={segmentInputType} size={'md'} />
                  </Flex>
                  <Flex sx={{ alignItems: 'center', width: '100%' }}>
                    <InjuryDummy
                      gender={gender}
                      orientation={segmentInputType}
                      selectedPartName={bodyPart?.id}
                      onClick={onClickBodyPart}
                      disabledTooltip={true}
                    />
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  sx={{
                    justifyContent: 'center',
                    gap: ['18px', '18px', '52px', '18px'],
                    mt: [0, 0, 2, 0],
                  }}
                >
                  <Flex
                    sx={{
                      width: ['220px', '220px', '180px', '220px'],
                      height: ['470px', '470px', '384px', '470px'],
                    }}
                  >
                    <InjuryDummy
                      gender={gender}
                      orientation={'front'}
                      selectedPartName={bodyPart?.id}
                      onClick={onClickBodyPart}
                      disabledTooltip={true}
                    />
                  </Flex>
                  <Flex
                    sx={{
                      width: ['220px', '220px', '180px', '220px'],
                      height: ['470px', '470px', '384px', '470px'],
                    }}
                  >
                    <InjuryDummy
                      gender={gender}
                      orientation={'back'}
                      selectedPartName={bodyPart?.id}
                      onClick={onClickBodyPart}
                      disabledTooltip={true}
                    />
                  </Flex>
                </Flex>
              )}
              <Flex
                sx={{
                  px: 3,
                  py: '10px',
                  bg: 'grey-50',
                  borderRadius: '35px',
                  maxWidth: !smAndDown && '412px',
                }}
              >
                <Text variant={'p2'} color={'indigo-500'}>
                  {formatMessage({ id: 'player.modal-injury.selection.part.guide' })}
                </Text>
              </Flex>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                gap: 3,
                flex: 1,
                borderLeft: 'default',
                pt: [0, 0, 2, 2],
                pb: [3, 3, 3, 2],
                px: [2, 4, 3],
                height: !mdAndDown && '100%',
                overflowY: !mdAndDown && 'auto',
              }}
            >
              <Text sx={{ fontWeight: 700 }} variant={'h4'}>
                {formatMessage({ id: 'player.modal-injury.detail-selection.header' })}
              </Text>
              <Flex sx={{ flexDirection: 'column', gap: 1 }}>
                <Text sx={{ fontWeight: 500 }} variant={'p1'}>
                  {`${formatMessage({ id: 'player.modal-injury.detail-selection.date.title' })}*`}
                </Text>
                <Flex sx={{ gap: 1 }}>
                  <Flex
                    sx={{
                      width: '142px',
                      border: '1px solid',
                      borderColor: '#d8dce6',
                      borderRadius: '4px',
                    }}
                  >
                    <OverlayCalendar
                      value={moment(occurDate).toDate()}
                      onChange={(date: Date) =>
                        setOccurDate(
                          moment(date).startOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
                        )
                      }
                      maxDate={moment().toDate()}
                      placement={'bottom-start'}
                      offset={[0, 4]}
                    />
                  </Flex>
                  <Flex sx={{ gap: 1, alignItems: 'center' }}>
                    <LabelInjury
                      checked={historyStatus === InjuryHistoryStatus.RELAPSE}
                      onClick={onToggleHistoryStatus}
                    />
                    <Text>
                      {formatMessage({ id: 'player.modal-injury.detail-selection.relapse.text' })}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex sx={{ flexDirection: 'column', gap: 1 }}>
                <Text sx={{ fontWeight: 500 }} variant={'p1'}>
                  {formatMessage({ id: 'player.modal-injury.detail-selection.cause.title' })}
                </Text>
                <TextEditable
                  isEditable={true}
                  width={'100%'}
                  value={reason}
                  setValue={(value) => onChange(value, setReason)}
                  placeholder={formatMessage({
                    id: 'player.modal-injury.detail-selection.cause.placeholder',
                  })}
                  textareaRef={reasonRef}
                  resizeAction={() => resizeAction(reasonRef.current)}
                />
                <Text
                  sx={{ textAlign: 'right' }}
                  variant={'p2'}
                  color={'text-secondary'}
                >{`${reason.length}/100`}</Text>
              </Flex>
              <Flex sx={{ flexDirection: 'column', gap: 1 }}>
                <Text sx={{ fontWeight: 500 }} variant={'p1'}>
                  {formatMessage({ id: 'player.modal-injury.detail-selection.diagnosis.title' })}
                </Text>
                <TextEditable
                  isEditable={true}
                  width={'100%'}
                  value={diagnosis}
                  setValue={(value) => onChange(value, setDiagnosis)}
                  placeholder={formatMessage({
                    id: 'player.modal-injury.detail-selection.diagnosis.placeholder',
                  })}
                  textareaRef={diagnosisRef}
                  resizeAction={() => resizeAction(diagnosisRef.current)}
                />
                <Text
                  sx={{ textAlign: 'right' }}
                  variant={'p2'}
                  color={'text-secondary'}
                >{`${diagnosis.length}/100`}</Text>
              </Flex>
              <Flex sx={{ flexDirection: 'column', gap: 1 }}>
                <Text sx={{ fontWeight: 500 }} variant={'p1'}>
                  {formatMessage({
                    id: 'player.modal-injury.detail-selection.medical-record.title',
                  })}
                </Text>
                <TextEditable
                  isEditable={true}
                  width={'100%'}
                  value={medicalRecord}
                  setValue={(value) => onChange(value, setMedicalRecord)}
                  placeholder={formatMessage({
                    id: 'player.modal-injury.detail-selection.medical-record.placeholder',
                  })}
                  textareaRef={medicalRecordRef}
                  resizeAction={() => resizeAction(medicalRecordRef.current)}
                />
                <Text
                  sx={{ textAlign: 'right' }}
                  variant={'p2'}
                  color={'text-secondary'}
                >{`${medicalRecord.length}/100`}</Text>
              </Flex>
              <Flex sx={{ flexDirection: 'column', gap: 1 }}>
                <Text sx={{ fontWeight: 500 }} variant={'p1'}>
                  {`${formatMessage({
                    id: 'player.modal-injury.detail-selection.return-date.title',
                  })}*`}
                </Text>
                <Flex sx={{ gap: 1 }}>
                  <Flex
                    sx={{
                      width: '142px',
                      border: '1px solid',
                      borderColor: '#d8dce6',
                      borderRadius: '4px',
                    }}
                  >
                    <OverlayCalendar
                      value={moment(returnDate).toDate()}
                      onChange={(date: Date) =>
                        setReturnDate(
                          moment(date).startOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
                        )
                      }
                      disabled={returnStatus === InjuryReturnStatus.UNDECIDED}
                      placement={'top-start'}
                      offset={[0, 4]}
                      titleColor={
                        returnStatus === InjuryReturnStatus.UNDECIDED ? 'grey-300' : undefined
                      }
                      buttonProps={{
                        sx: {
                          bg: returnStatus === InjuryReturnStatus.UNDECIDED && 'grey-50',
                        },
                      }}
                    />
                  </Flex>
                  <Flex sx={{ gap: 1, alignItems: 'center' }}>
                    <LabelInjury
                      checked={returnStatus === InjuryReturnStatus.UNDECIDED}
                      onClick={onToggleReturnStatus}
                    />
                    <Text>
                      {formatMessage({
                        id: 'player.modal-injury.detail-selection.return-date.undecide',
                      })}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex sx={{ flexDirection: 'column', gap: 1 }}>
                <Text sx={{ fontWeight: 500 }} variant={'p1'}>
                  {formatMessage({ id: 'player.modal-injury.detail-selection.return-info.title' })}
                </Text>
                <TextEditable
                  isEditable={true}
                  width={'100%'}
                  value={returnInfo}
                  setValue={(value) => onChange(value, setReturnInfo)}
                  placeholder={formatMessage({
                    id: 'player.modal-injury.detail-selection.return-info.placeholder',
                  })}
                  textareaRef={returnInfoRef}
                  resizeAction={() => resizeAction(returnInfoRef.current)}
                />
                <Text
                  sx={{ textAlign: 'right' }}
                  variant={'p2'}
                  color={'text-secondary'}
                >{`${returnInfo.length}/100`}</Text>
              </Flex>
              <SectionInjuryStatus
                active={injuryStatus === InjuryStatus.END}
                onToggle={onToggleInjuryStatus}
              />
              <Flex
                sx={{
                  flexDirection: 'column',
                  gap: '12px',
                  p: 3,
                  bg: 'grey-50',
                  borderRadius: '20px',
                }}
              >
                {fileList.length ? (
                  fileList.map((file, index) => (
                    <InjuryFileItem
                      id={index}
                      fileName={file.name}
                      key={file.name}
                      onDelete={onDeleteFile}
                    />
                  ))
                ) : (
                  <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: '14px' }}>
                    <Input
                      onChange={onChangeFile}
                      sx={{ display: 'none' }}
                      ref={fileRef}
                      type={'file'}
                      multiple
                    />
                    <Button
                      onClick={onAttachFile}
                      sx={{ flexShrink: 1 }}
                      variant={'secondary'}
                      color={'grey'}
                      size={'md'}
                    >
                      {formatMessage({ id: 'player.modal-injury.detail-selection.file.button' })}
                    </Button>
                    <Text
                      sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
                      variant={'p2'}
                      color={'text-secondary'}
                    >
                      {formatMessage({ id: 'player.modal-injury.detail-selection.file.text' })}
                    </Text>
                  </Flex>
                )}
                {fileList.length && fileList.length < 3 ? (
                  <Flex>
                    <Input
                      onChange={onChangeFile}
                      sx={{ display: 'none' }}
                      ref={fileRef}
                      type={'file'}
                      multiple
                    />
                    <Button onClick={onAttachFile} variant={'secondary'} color={'grey'} size={'md'}>
                      {formatMessage({ id: 'player.modal-injury.detail-selection.file.button' })}
                    </Button>
                  </Flex>
                ) : null}
              </Flex>
            </Flex>
          </Flex>
          <ModalInjuryCancel
            text={formatMessage({ id: 'player.modal-injury.register-cancel.title' })}
            open={openRegisterCancelModal}
            onClose={() => setOpenRegisterCancelModal(false)}
            onConfirm={onCloseOpenModal}
          />
          <ModalInjuryCancel
            text={formatMessage({ id: 'player.modal-injury.update-cancel.title' })}
            open={openEditCancelModal}
            onClose={() => setOpenEditCancelModal(false)}
            onConfirm={onCloseOpenModal}
          />
          <ModalFileLoading open={hasUploadFile} />
        </Modal>
      </ResponsiveContextProvider>
    </ThemeProvider>
  )
}
