import { useCallback, useMemo } from 'react'

import { useRouter } from 'next/router'
import { pathToRegexp } from 'path-to-regexp'

import { SportsTeamAuthority, SubscriptionStatusEnum } from '@plco-pro/graphqls/react.generated'
import { useViewer } from '@plco-pro/hooks/viewer/viewer'
import { accessMap, PermissionMapKey, roleMap } from '@plco-pro/maps/authorization'
import { useStore } from '@plco-pro/stores'

export type TeamSubscription = {
  status: SubscriptionStatusEnum
  maxPlayerCount: number
  maxTeamCount: number
  isFreeTrial: boolean
  isHyper: boolean
}
export type TeamLicense = {
  id: string
  isActivate: boolean
}

const QUERY_PARAM_IGNORELIST = ['_ga', '_gl']

const RESTRICTED_PAGES = ['/teamchart', '/monitoring', '/insight', '/event', '/blog']

const sanitizePath = (path: string) => {
  // url 오브젝트를 만들고, query params 를 추출한다.
  const protocolHostPlaceholder = 'https://example.com'
  const urlObject = new URL(`${protocolHostPlaceholder}${path}`)
  const { pathname, searchParams: params } = urlObject

  // delete query params to ignore
  QUERY_PARAM_IGNORELIST.forEach((queryParam) => {
    params.delete(queryParam)
  })

  // build sanitized path
  const separator = params.toString().length > 0 ? '?' : ''
  const sanitizedPath = `${pathname}${separator}${params.toString()}`

  return sanitizedPath
}

export const useAuthorization = () => {
  const store = useStore()
  const router = useRouter()

  const { teamAuthority } = useViewer()

  const userRole: SportsTeamAuthority | null = useMemo(() => {
    return teamAuthority || null
  }, [teamAuthority])

  const {
    teamSubscriptionStatus: status,
    teamSubscriptionMaxTeamCount: maxTeamCount,
    teamSubscriptionMaxPlayerCount: maxPlayerCount,
    teamSubscriptionIsFreeTrial: isFreeTrial,
    isHyper,
  } = store.navigation

  const teamSubscription = useMemo(() => {
    return status === null ||
      maxPlayerCount === null ||
      maxTeamCount === null ||
      isFreeTrial === null
      ? null
      : {
          status,
          maxPlayerCount,
          maxTeamCount,
          isFreeTrial,
          isHyper,
        }
  }, [isFreeTrial, isHyper, maxPlayerCount, maxTeamCount, status])

  const teamLicense: TeamLicense | null = useMemo(() => {
    const id = store.navigation.teamLicenseId
    const isActivate = store.navigation.teamLicenseIsActivate

    if (id === null || isActivate === null) return null

    return {
      id,
      isActivate,
    }
  }, [store.navigation.teamLicenseId, store.navigation.teamLicenseIsActivate])

  const hasRole = useCallback(
    (role: SportsTeamAuthority): boolean => {
      if (!userRole) return false

      return userRole === role
    },
    [userRole],
  )

  const hasPermission = useCallback(
    (permission: PermissionMapKey): boolean => {
      if (!userRole) {
        return false
      }

      return roleMap[permission].includes(userRole)
    },
    [userRole],
  )

  const hasSomePermissions = useCallback(
    (permissions: PermissionMapKey[]): boolean => {
      if (!userRole) {
        return false
      }

      return permissions.some((permission) => roleMap[permission].includes(userRole))
    },
    [userRole],
  )

  const hasEveryPermissions = useCallback(
    (permissions: PermissionMapKey[]): boolean => {
      if (!userRole) {
        return false
      }

      return permissions.every((permission) => roleMap[permission].includes(userRole))
    },
    [userRole],
  )

  const hasValidSubscription = useMemo(() => {
    if (!teamSubscription) {
      return null
    }

    // skip subscription check for roles without requirement
    if (!hasPermission('REQUIRE_VALID_SUBSCRIPTION')) {
      return true
    }

    const { status } = teamSubscription

    return status === 'ACTIVATE' || status === 'GRACE_PERIOD'
  }, [hasPermission, teamSubscription])

  const hasValidLicense = useMemo(() => {
    if (!teamLicense) {
      return null
    }

    const { id, isActivate } = teamLicense

    return id !== null && isActivate
  }, [teamLicense])

  const hasValidPlan = useMemo(() => {
    if (hasValidSubscription === null && hasValidLicense === null) return null

    return (
      (hasValidSubscription === true && hasValidLicense === null) ||
      (hasValidSubscription === null && hasValidLicense === true)
    )
  }, [hasValidLicense, hasValidSubscription])

  const hasAccess = (path: string): boolean => {
    if (!userRole) {
      return false
    }

    // strip path from parasite query params (e.g. _ga)
    const sanitizedPath = sanitizePath(path)

    if (!hasValidSubscription && !hasValidLicense && RESTRICTED_PAGES.includes(sanitizedPath)) {
      router.push('/dashboard')
    }

    // find path's matching permission by comparing to route regex
    let permission: PermissionMapKey | undefined
    accessMap.forEach((value, key) => {
      if (sanitizedPath.match(value) !== null) {
        permission = key
      }
    })

    return permission ? roleMap[permission].includes(userRole) : false
  }

  const hasPlanAccess = (path: string): boolean => {
    if (!userRole) {
      return false
    }

    // strip path from parasite query params (e.g. _ga)
    const sanitizedPath = sanitizePath(path)

    const { status, isFreeTrial } = teamSubscription || {}

    if (sanitizedPath.match(pathToRegexp('/plan/pay'))) {
      return isFreeTrial || !hasValidPlan
    } else if (sanitizedPath.match(pathToRegexp('/plan/pay/pricing(.*)'))) {
      return (
        (status === 'ACTIVATE' && isFreeTrial) ||
        status === 'INACTIVATE' ||
        (!hasValidPlan && hasValidLicense === null)
      )
    } else if (sanitizedPath.match(pathToRegexp('/plan/billing'))) {
      return !isFreeTrial && hasValidLicense === null
    } else if (sanitizedPath.match(pathToRegexp('/plan/billing/pricing(.*)'))) {
      return status === 'ACTIVATE' && !isFreeTrial && hasValidLicense === null
    } else if (sanitizedPath.match(pathToRegexp('/plan/billing/license'))) {
      return hasValidLicense !== null
    } else {
      return true
    }
  }

  const hasDeleteTeamAccess = useMemo(() => {
    if (!teamSubscription && !hasValidLicense) {
      return true
    }

    if (!hasPermission('REQUIRE_VALID_SUBSCRIPTION')) {
      return true
    }

    return !!teamSubscription?.isFreeTrial
  }, [hasPermission, hasValidLicense, teamSubscription])

  const hasPlanInfoAccess = () => {
    const { isFreeTrial } = teamSubscription || {}

    return hasValidSubscription && !isFreeTrial
  }

  const isSubscripting = store.navigation.teamSubscriptionIsSubscripting

  const isAdmin = hasRole(SportsTeamAuthority.ADMIN)

  const isOwner = hasRole(SportsTeamAuthority.OWNER)

  const isObserver = hasRole(SportsTeamAuthority.OBSERVER)

  return {
    hasRole,
    hasPermission,
    hasSomePermissions,
    hasEveryPermissions,
    hasValidSubscription,
    hasValidLicense,
    hasValidPlan,
    hasAccess,
    hasPlanAccess,
    hasDeleteTeamAccess,
    hasPlanInfoAccess,
    isSubscripting,
    isHyper,
    isAdmin,
    isOwner,
    isObserver,
  }
}
