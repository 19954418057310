import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import _clone from 'lodash/clone'
import _debounce from 'lodash/debounce'
import { useRouter } from 'next/router'

import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useAuthorization } from '@plco-pro/hooks/authorization'
import { useChannelTalk } from '@plco-pro/hooks/channel-talk'
import { useStore } from '@plco-pro/stores'

export type Path = {
  asPath: string
  pathname: string
}

export type RouterContext = {
  paths?: Path[]
  goBackToDifferentPathname?: () => void
  goBackToAuthorizedPathname?: () => void
  goBackWithSkippedPathname?: (pathname: string) => void
  hash?: string | null
  loading?: boolean
}

const RouterContext = createContext<RouterContext>({})

export const useRouterContext = () => {
  return useContext(RouterContext)
}

const RouterProvider: React.FunctionComponent = ({ children }) => {
  const router = useRouter()
  const { pageTrack } = useAnalytics()
  const { navigation } = useStore()
  const { hasAccess } = useAuthorization()
  const { track, setPage } = useChannelTalk()

  const [paths, setPaths] = useState<Path[]>([])
  const [hash, setHash] = useState<string | null>(null)
  const [routeLoading, setRouteLoading] = useState(false)

  const pageViewProperty = useRef<Record<string, any>>({})

  useEffect(() => {
    pageViewProperty.current = navigation.pageViewProperty
  }, [navigation.pageViewProperty])

  const debouncedUpdatePaths = useMemo(
    () =>
      _debounce(
        (prevPaths: Path[], newPath: Path) => {
          const prevPathsLength = prevPaths.length

          if (prevPathsLength >= 1 && newPath.asPath === prevPaths[prevPathsLength - 1].asPath) {
            // don't update
          } else if (
            prevPathsLength >= 2 &&
            newPath.asPath === prevPaths[prevPathsLength - 2].asPath
          ) {
            setPaths([...prevPaths].slice(0, -1))
          } else {
            setPaths([...prevPaths, newPath])
          }
        },
        1000,
        { leading: true },
      ),
    [],
  )

  const goBackToDifferentPathname = useCallback(() => {
    const currentPathname = router.pathname
    let newPaths: Path[] = []
    let destinationAsPath = '/dashboard'

    for (let destinationIndex = paths.length - 2; destinationIndex >= 0; destinationIndex--) {
      if (paths[destinationIndex].pathname !== currentPathname) {
        destinationAsPath = paths[destinationIndex].asPath
        newPaths = [...paths].slice(0, destinationIndex + 1)
        break
      }
    }
    setPaths(newPaths)
    router.push(destinationAsPath)
  }, [paths, router])

  const goBackToAuthorizedPathname = useCallback(() => {
    const currentPathname = router.pathname
    let newPaths: Path[] = []
    let destinationAsPath = '/dashboard'

    for (let destinationIndex = paths.length - 2; destinationIndex >= 0; destinationIndex--) {
      const isUnauthorizedPage = paths[destinationIndex].asPath.match('/unauthorized') !== null

      if (
        paths[destinationIndex].pathname !== currentPathname &&
        hasAccess(paths[destinationIndex].asPath) &&
        !isUnauthorizedPage
      ) {
        destinationAsPath = paths[destinationIndex].asPath
        newPaths = [...paths].slice(0, destinationIndex)
        break
      }
    }

    setPaths(newPaths)
    router.push(destinationAsPath)
  }, [hasAccess, paths, router])

  const goBackWithSkippedPathname = useCallback(
    (pathName: string) => {
      const currentPathname = router.pathname

      let newPaths: Path[] = []
      let destinationAsPath = '/dashboard'

      for (let destinationIndex = paths.length - 2; destinationIndex >= 0; destinationIndex--) {
        if (!currentPathname.includes(pathName)) {
          destinationAsPath = paths[destinationIndex].asPath
          newPaths = [...paths].slice(0, destinationIndex)
          break
        }
      }
      setPaths(newPaths)
      router.push(destinationAsPath)
    },
    [paths, router],
  )

  useEffect(() => {
    debouncedUpdatePaths(paths, {
      asPath: router.asPath,
      pathname: router.pathname,
    })
  }, [debouncedUpdatePaths, paths, router])

  const updateHash = useCallback((url: string) => {
    const matchResult = url.match(/#[^#\s]*/)
    if (matchResult) {
      const newHash = matchResult[0].replace(/#/, '')
      setHash(newHash)
    } else {
      setHash(null)
    }
  }, [])

  useEffect(() => {
    // for router events on change complete not trigger when first load;
    pageTrack(router.route)
    updateHash(router.asPath)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setRouteLoading(true)
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [router.events])

  useEffect(() => {
    const handleRouteChangeComplete = (url: string) => {
      updateHash(url)
      setRouteLoading(false)
      const properties = { ...pageViewProperty.current }

      if (url !== '/') {
        pageTrack(url, properties)
      }

      navigation.clearPageViewProperty()
      setPage(window.location.origin + url)
      track('PageView')
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [navigation, pageTrack, router.events, setPage, track, updateHash])

  useEffect(() => {
    const handleRouteChangeError = () => {
      setRouteLoading(false)
    }

    router.events.on('routeChangeError', handleRouteChangeError)
    return () => {
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [router.events])

  useEffect(() => {
    const handleHashChangeComplete = (url: string) => {
      updateHash(url)
    }

    router.events.on('hashChangeComplete', handleHashChangeComplete)
    return () => {
      router.events.off('hashChangeComplete', handleHashChangeComplete)
    }
  }, [router.events, updateHash])

  // remove unwanted query params like _ga
  useEffect(() => {
    const hasGAParam = !!router.query['_ga']
    if (!hasGAParam) return

    const copyRouterQuery = _clone(router.query)
    delete copyRouterQuery['_ga']

    router.replace({ pathname: router.pathname, query: copyRouterQuery })
  }, [router])

  const value = useMemo(
    () => ({
      paths,
      goBackToDifferentPathname,
      goBackToAuthorizedPathname,
      goBackWithSkippedPathname,
      hash,
      loading: routeLoading,
    }),
    [
      goBackWithSkippedPathname,
      goBackToAuthorizedPathname,
      goBackToDifferentPathname,
      hash,
      paths,
      routeLoading,
    ],
  )

  return <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
}

export default RouterProvider
