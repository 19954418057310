import { GraphQLErrors } from '@apollo/client/errors'
import { useRouter } from 'next/router'

import { useI18n } from './i18n'
import { useToast } from './toast'
import { useViewer } from './viewer/viewer'

export const useFeedbackError = () => {
  const { showToast } = useToast()
  const { formatMessage } = useI18n()
  const { push } = useRouter()
  const { refetch } = useViewer()

  const showFeedbackError = (graphQLErrors: GraphQLErrors, redirect = false) => {
    const statusCode = graphQLErrors[0].extensions.statusCode
    const errorCode = graphQLErrors[0].extensions.errorCode

    if (statusCode !== 400) {
      showToast(formatMessage({ id: `ERROR.${errorCode}` }), { status: 'error' })

      if (redirect) {
        push('/dashboard')
        refetch()
      }

      return
    }

    showToast(formatMessage({ id: 'toast.modal-check-condition-reminder.error' }), {
      status: 'error',
    })
  }

  return showFeedbackError
}
