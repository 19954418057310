export const UPDATE_DATA_RANGE = 28
export const KO_LANGUAGE = 'ko'
export const EN_LANGUAGE = 'en'
export const JA_LANGUAGE = 'ja'
export const VN_LANGUAGE = 'vn-VN'

// date
export const DATE_FORMAT = {
  YEAR_TO_DAY: 'YYYY-MM-DD',
  MONTH: 'MMM',
  DAY: 'D',
}
