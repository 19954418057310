import {
  Feedback,
  FeedbackConditionNotGoodData,
  FeedbackConditionPlummetData,
  FeedbackDataFragment,
  FeedbackSorenessSevereData,
  FeedbackType,
  FeedbackWorkloadMemoData,
  FeedbackWorkloadRiskAtInjuryData,
} from '@plco-pro/graphqls/react.generated'

import { useI18n } from './i18n'

export const useMappingFeedback = () => {
  const { formatMessage: f } = useI18n()

  // 피드백 리스트 타이틀
  const getFeedbackListTitle = (feedback: Pick<FeedbackDataFragment, 'type' | 'contentsData'>) => {
    const type = feedback.type

    if (type === FeedbackType.NORMAL) {
      return f({
        id: 'feedback.general-feedback',
      })
    }

    if (type === FeedbackType.REQUEST) {
      return f({
        id: 'feedback.feedback-request',
      })
    }

    if (type === FeedbackType.CONDITION_NOT_GOOD) {
      return f({
        id: 'feedback.type.bad-condition',
      })
    }

    if (type === FeedbackType.CONDITION_PLUMMET) {
      return f({
        id: 'feedback.type.plummeted-condition',
      })
    }

    if (type === FeedbackType.WORKLOAD_RISK_AT_INJURY) {
      const injuryData = feedback.contentsData as FeedbackWorkloadRiskAtInjuryData
      const { label } = injuryData

      return f({
        id: `feedback.type.${label}`,
      })
    }

    if (type === FeedbackType.SORENESS_SEVERE) {
      const sorenessData = feedback.contentsData as FeedbackSorenessSevereData

      return `${sorenessData.bodyPart.value}  ${f({
        id: 'feedback.type.severe-soreness',
      })}`
    }

    if (type === FeedbackType.WORKLOAD_MEMO) {
      const workloadMemoData = feedback.contentsData as FeedbackWorkloadMemoData
      const eventTitle = workloadMemoData.eventData?.title

      return f({
        id: eventTitle ?? 'feedback.type.normal-workout',
      })
    }

    return f({ id: 'notification.general' })
  }

  // 피드백 리스트 타입, 칩
  const getFeedbackListType = (feedback: Pick<FeedbackDataFragment, 'type' | 'contentsData'>) => {
    const type = feedback.type

    if (type === FeedbackType.WORKLOAD_MEMO) {
      const workloadMemoData = feedback.contentsData as FeedbackWorkloadMemoData
      const eventType = workloadMemoData.eventData?.type

      if (eventType) {
        return f({ id: `feedback.tag.${eventType}` })
      }

      return f({ id: 'feedback.tag.WORKLOAD_MEMO' })
    }

    return f({ id: `feedback.tag.${type}` })
  }

  // 피드백 리스트 내용
  const getFeedbackListMessage = (feedback: Feedback) => {
    const type = feedback.type
    const message = feedback.message

    if (type === FeedbackType.WORKLOAD_MEMO) {
      const workloadMemoData = feedback.contentsData as FeedbackWorkloadMemoData
      const eventData = workloadMemoData.eventData
      const eventType = eventData?.type

      if (!eventType) {
        return f({ id: 'feedback.type.normal-workout' })
      }

      return eventData?.title
    }

    if (type === FeedbackType.CONDITION_NOT_GOOD) {
      return f({ id: 'feedback.type.bad-condition' })
    }

    if (type === FeedbackType.CONDITION_PLUMMET) {
      return f({ id: 'feedback.type.plummeted-condition' })
    }

    if (type === FeedbackType.NORMAL || type === FeedbackType.REQUEST) {
      return message
    }

    if (type === FeedbackType.WORKLOAD_RISK_AT_INJURY) {
      const injuryData = feedback.contentsData as FeedbackWorkloadRiskAtInjuryData

      return f({
        id: `feedback.type.${injuryData.label}`,
      })
    }

    if (type === FeedbackType.SORENESS_SEVERE) {
      const severeData = feedback.contentsData as FeedbackSorenessSevereData

      return `${severeData.bodyPart.value}  ${f({
        id: 'feedback.type.severe-soreness',
      })}`
    }
  }

  // 피드백 제안 내용
  const getFeedbackSuggestionContents = (
    feedback?: Pick<FeedbackDataFragment, 'type' | 'contentsData'>,
  ) => {
    if (!feedback?.contentsData) {
      return
    }

    const type = feedback.type

    const { __typename, ...contetsData } = feedback.contentsData

    return {
      workloadMemoData:
        type === FeedbackType.WORKLOAD_MEMO ? (contetsData as FeedbackWorkloadMemoData) : undefined,
      sorenessSevereData:
        type === FeedbackType.SORENESS_SEVERE
          ? (contetsData as FeedbackSorenessSevereData)
          : undefined,
      conditionNotGoodData:
        type === FeedbackType.CONDITION_NOT_GOOD
          ? (contetsData as FeedbackConditionNotGoodData)
          : undefined,
      conditionPlummetData:
        type === FeedbackType.CONDITION_PLUMMET
          ? (contetsData as FeedbackConditionPlummetData)
          : undefined,
      workloadRiskAtInjuryData:
        type === FeedbackType.WORKLOAD_RISK_AT_INJURY
          ? (contetsData as FeedbackWorkloadRiskAtInjuryData)
          : undefined,
    }
  }

  return {
    getFeedbackListTitle,
    getFeedbackListType,
    getFeedbackListMessage,
    getFeedbackSuggestionContents,
  }
}
