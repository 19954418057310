import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useRouter } from 'next/router'

import { Flex } from '@plco-pro/components/atoms/flex'
import { Spinner } from '@plco-pro/components/atoms/spinner'
import { isRegister } from '@plco-pro/domain/register'
import {
  NavigationViewerQuery,
  QualificationType,
  useNavigationByPlanLazyQuery,
  useNavigationQualificationLazyQuery,
  useNavigationViewerQuery,
} from '@plco-pro/graphqls/react.generated'
import { CUSTOM_TIMEOUT_MESSAGE } from '@plco-pro/graphqls/utils'
import { useAuthorization } from '@plco-pro/hooks/authorization'
import { useIdentify } from '@plco-pro/hooks/identify'
import { useRouterContext } from '@plco-pro/providers/router'
import { useStore } from '@plco-pro/stores'
import { setUser } from '@plco-pro/utils/libs/log'
import { getTeamId, getTeams } from '@plco-pro/utils/navigation'

const DASHBOARD_REDIRECT_ROUTES = ['/', '/teams/new', '/register/welcome', '/auth-callback/login']

const WHITE_LIST = [
  { path: '/auth-callback/logout' },
  { path: '/blog', queryParams: ['errorCode'] },
  { path: '/blog/*', queryParams: ['errorCode'] },
]

const NavigationProvider: React.FunctionComponent = observer(({ children }) => {
  const router = useRouter()
  const { navigation, register } = useStore()
  const { hasValidPlan } = useAuthorization()

  const [loading, setLoading] = useState(true)

  // analytics
  useIdentify()

  const history = useRouterContext()

  const [navigationByPlanQuery] = useNavigationByPlanLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      navigation.changeSubscription(data.teamSubscriptionItem || null)
      navigation.changeLicense(data.licenseByTeam || null)
      navigation.changeHyperStatus(data.teamSubscriptionItem?.plan.id === 'HYPER')

      setLoading(false)
    },
  })

  const setActiveTeam = useCallback(
    (data: NavigationViewerQuery) => {
      const teams = getTeams(data)
      const currentActiveTeam =
        teams?.find((team) => team?.id === navigation.activeTeamId) || teams[0]
      const viewer = data?.sports?.viewer

      // find total count properties
      const totalTeamCount = viewer?.teamMaps?.map((teamMap) => teamMap.team).length || 0

      navigation.setActiveTeamId(currentActiveTeam.id)

      // update totals in store
      navigation.setTotalTeamCount(totalTeamCount)
    },
    [navigation],
  )

  const initializeTeamData = useCallback(
    (data: NavigationViewerQuery) => {
      if (!data.sports.viewer || !isRegister(data.sports.viewer)) return
      const teamId = getTeamId(data)

      if (!teamId) {
        const url = '/teams/new'

        if (router.route !== url && router.route !== '/register/team-code') {
          router.replace(url)
        }
        return
      }

      setActiveTeam(data)

      const url = '/dashboard'

      const isFirstHistory = history.paths?.length === 1

      if (DASHBOARD_REDIRECT_ROUTES.includes(router.route) && isFirstHistory) {
        router.replace(url)
      }
    },
    [router, setActiveTeam, history.paths?.length],
  )

  const handleCompletedNavigationViewer = (data: NavigationViewerQuery) => {
    const viewer = data?.sports?.viewer
    if (!viewer) return

    if (isRegister(viewer)) {
      initializeTeamData(data)
      navigation.setInitialized(true)
    } else {
      register.viewerIdentity(viewer)

      if (!router.route.includes('/register')) {
        router.replace('/register/welcome')
      }
    }
  }

  // query to fetch viewer data
  const skipNavigationViewer = !!WHITE_LIST.find(({ path, queryParams }) => {
    return (
      new RegExp(path).test(router.pathname) &&
      (queryParams ?? []).every((query) => router.asPath.includes(`${query}=`))
    )
  })
  const { data } = useNavigationViewerQuery({
    skip: skipNavigationViewer,
    onCompleted: (data) => {
      if (!data) {
        return
      }

      handleCompletedNavigationViewer(data)
    },
    onError: (error) => {
      navigation.changeSubscription(null)
      navigation.changeLicense(null)
      if (error?.message === CUSTOM_TIMEOUT_MESSAGE) {
        return router.push({
          pathname: '/blog',
          query: {
            errorCode: 'timeout',
          },
        })
      }
      router.push('/auth-callback/logout')
    },
    context: {
      skipGlobalHandling: true,
    },
  })

  const [qualificationQuery] = useNavigationQualificationLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.qualification.qualificationType === QualificationType.LICENSE) {
        navigationByPlanQuery({
          variables: { teamId: navigation.activeTeamId || '', includeLicense: true },
        })
      } else {
        navigationByPlanQuery({
          variables: { teamId: navigation.activeTeamId || '', includeSubscription: true },
        })
      }
    },
  })

  useEffect(() => {
    if (!data) {
      return
    }

    const { viewer } = data.sports

    setUser({
      id: viewer?.id,
      email: viewer?.email,
      name: viewer?.name,
    })
  }, [data])

  useEffect(() => {
    if (typeof window === 'undefined' || !data?.sports.viewer?.id) {
      return
    }

    // @ts-ignore
    window.dataLayer?.push(function () {
      this.set('userId', data.sports.viewer?.id)
    })
  }, [data?.sports.viewer?.id])

  useEffect(() => {
    if (!data) return

    initializeTeamData(data)
  }, [data, initializeTeamData])

  useEffect(() => {
    // 팀이 있는 경우 flow
    if (data && navigation.activeTeamId) {
      qualificationQuery({
        variables: {
          teamId: navigation.activeTeamId,
        },
      })

      return
    }
    setLoading(false)
  }, [data, navigation.activeTeamId, qualificationQuery])

  const isLoading = useMemo(() => {
    if (!loading) {
      return false
    }

    if (navigation.initialized) {
      return hasValidPlan === null
    }

    return true
  }, [hasValidPlan, loading, navigation.initialized])

  return (
    <>
      {isLoading ? (
        <Flex sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <Spinner />
        </Flex>
      ) : (
        children
      )}
    </>
  )
})

export default NavigationProvider
