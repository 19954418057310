import { useMemo } from 'react'

import { Flex, Text } from '@plco-pro/components/atoms'
import { EventType, FeedbackType } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks'

interface Props {
  feedbackType: FeedbackType
  eventType?: EventType
}

export const ChipFeedbackType = ({ feedbackType, eventType }: Props) => {
  const { formatMessage } = useI18n()

  const feedbackChipData = useMemo(() => {
    const text = formatMessage({ id: `feedback.tag.${feedbackType}` })
    switch (feedbackType) {
      case 'WORKLOAD_RISK_AT_INJURY':
        return {
          color: 'pink-500',
          bg: 'pink-100',
          text,
        }
      case 'SORENESS_SEVERE':
        return {
          color: 'amber-700',
          bg: 'amber-100',
          text,
        }
      case 'CONDITION_NOT_GOOD':
      case 'CONDITION_PLUMMET':
        return {
          color: 'teal-600',
          bg: 'teal-100',
          text,
        }
      default:
        return {
          color: 'violet-500',
          bg: 'violet-100',
          text: formatMessage({ id: `feedback.tag.${eventType || FeedbackType.WORKLOAD_MEMO}` }),
        }
    }
  }, [feedbackType, eventType, formatMessage])

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        px: 1,
        py: '4px',
        borderRadius: '4px',
        bg: feedbackChipData.bg,
      }}
    >
      <Text sx={{ fontSize: '11px', fontWeight: 'bold', color: feedbackChipData.color }}>
        {feedbackChipData.text}
      </Text>
    </Flex>
  )
}
