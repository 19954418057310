import { EmptyObject } from '@common/utils'

import { EventType, FeedbackType, ReportFileType } from '@plco-pro/graphqls/react.generated'

type PrependNextNum<A extends Array<unknown>> = A['length'] extends infer T
  ? ((t: T, ...a: A) => void) extends (...x: infer X) => void
    ? X
    : never
  : never
type EnumerateInternal<A extends Array<unknown>, N extends number> = {
  0: A
  1: EnumerateInternal<PrependNextNum<A>, N>
}[N extends A['length'] ? 0 : 1]

type Enumerate<N extends number> = EnumerateInternal<[], N> extends (infer E)[] ? E : never
// ex: RANGE<1, 5> = 1 | 2 | 3| 4
export type Range<FROM extends number, TO extends number> = Exclude<Enumerate<TO>, Enumerate<FROM>>

export type AnalyticsPattern = {
  pattern: string
  event: PageEventName
  track?: boolean
}

export enum AnalyticsEventType {
  // team management
  TEAM_DELETION = 'team_deletion',
  KICK_OUT = 'kick_out',
  // onboarding
  LOGIN = 'login',
  FIRST_LOGIN = 'first_login',
  LOGOUT = 'logout',
  REGISTRATION_ACCOUNT_CREATION = 'registration_account_creation',
  REGISTRATION_TEAM_CREATION = 'registration_team_creation',
  REGISTRATION_TEAM_JOIN = 'registration_team_join',
  TUTORIAL_COMPLETION_ONBOARDING = 'tutorial_completion_onboarding',
  COPY_TEAMCODE = 'copy_teamcode',
  SORTING_CLICKED_FOR_TABLE = 'sorting_clicked_for_table',
  ACCEPT_INVITATION = 'accept_invitation',
  REFUSAL_INVITATION = 'refusal_invitation',
  // new-dashboard
  CONDITION_ALARM_CLICKED = 'conditon_alarm_clicked',
  WORKOUT_ALARM_CLICKED = 'workout_alarm_clicked',
  NEWDASHBOARD_TAB_CLICKED = 'newdashboard_tab_clicked',
  CONDITION_ALARM_LIST_CLICKED = 'condition_alarm_list_clicked',
  WORKOUT_ALARM_LIST_CLICKED = 'workout_alarm_list_clicked',
  VIEW_TEAM_EVENT_ONLY = 'view_team_event_only',
  CLICK_GROUP_DATA = 'click_group_data',
  // dashboard
  DASHBOARD_CONDITION_CHECK_ALARM_CLICKED = 'dashboard_condition_check_alarm_clicked',
  DASHBOARD_WORKLOAD_CHECK_ALARM_CLICKED = 'dashboard_workload_check_alarm_clicked',
  DASHBOARD_DATE_CHANGING_CLICKED = 'dashboard_date_changing_clicked',
  DASHBOARD_INJURED_PLAYER_CLICKED = 'dashboard_injured_player_clicked',
  OPEN_DATA_POPOVER = 'open_data_popover',
  // monitoring
  MONITORING_DATE_CHANGING_CLICKED = 'monitoring_date_changing_clicked',
  MONITORING_CATEGORY_DATA_INDEX = 'monitoring_category_data_index', // TODO: rename
  // group
  GROUP_MAKING = 'group_making',
  GROUP_CLICKED = 'group_clicked',
  GROUP_MODIFICATION = 'group_modification',
  GROUP_DELETION = 'group_deletion',
  // schedule
  SCHEDULE_CREATION = 'schedule_creation',
  SCHEDULE_MODIFICATION = 'schedule_modification',
  SCHEDULE_DELETION = 'schedule_deletion',
  SCHEDULED_WORKLOAD_NOTIFICATION = 'scheduled_workload_notification',
  SCHEDULE_CREATION_TRAINING = 'schedule_creation_training',
  SCHEDULE_DUPLICATION = 'schedule_duplication',
  ATTENDANCE_ALARM_CLICKED = 'attendance_alarm_clicked',
  REPORT_CLICKED = 'report_clicked',
  REPORT_RESULT_SAVED = 'report_result_saved',
  REPORT_LINEUP_SAVED = 'report_lineup_saved',
  REPORT_RECORDCOMMENT_SAVED = 'report_recordcomment_saved',
  REPORT_MONITORING_CATEGORY_DATA_INDEX = 'report_monitoring_category_data_index',
  REPORT_DATAVIEW_CLICKED = 'report_dataview_clicked',
  // insight
  INSIGHT_DATE_CHANGING_CLICKED = 'insight_date_changing_clicked',
  // players
  STARRED_ADDITION = 'starred_addition',
  STARRED_CANCELLATION = 'starred_cancellation',
  CONDITION_CLICKED = 'condition_clicked',
  WORKLOAD_CLICKED = 'workload_clicked',
  ACWR_CLICKED = 'acwr_clicked',
  BODY_CLICKED = 'body_clicked',
  INJURY_CLICKED = 'injury_clicked',
  REGISTER_INJURY = 'register_injury',
  DELETE_INJURY = 'delete_injury',
  // profile
  EDIT_PROFILE_PICTURE = 'edit_profile_picture',
  EDIT_PROFILE = 'edit_profile',
  // blog
  MAIN_TAG_CLICKED = 'main_tag_clicked',
  SUB_TAG_CLICKED = 'sub_tag_clicked',
  // payment
  PLAN_SELECTED = 'plan_selected',
  PERSONS_SELECTED = 'persons_selected',
  PAYMENT_COMPLETED = 'payment_completed',
  PLAN_CANCEL = 'plan_cancel',
  PLAN_SUBSCRIPTION_BANNER_CLICKED = 'plan_subscription_banner_clicked',
  // uncategorized
  CHANNEL_TALK_CLICKED = 'channel_talk_clicked',
  // player modal opened
  PLAYER_PAGE_CLICKED = 'player_page_clicked',
  // traning report
  REPORT_TRAINING_TRAININGPOINT_SAVED = 'report_training_trainingpoint_saved',
  REPORT_TRAINING_SPECIALNOTES_SAVED = 'report_training_specialnotes_saved',
  REPORT_TRAINING_PLAYERMEMO_CLICKED = 'report_training_playermemo_clicked',
  REPORT_EXPORT_CLICK = 'report_export_click',
  REPORT_EXPORT_SAVE = 'report_export_save',
  // feedback
  REGISTER_FEEDBACK_CLICKED = 'register_feedback_clicked',
  REGISTER_FEEDBACK = 'register_feedback',
  EDIT_FEEDBACK = 'edit_feedback',
  DELETE_FEEDBACK = 'delete_feedback',
  VIEW_FEEDBACK = 'view_feedback',
  SAVE_FEEDBACK_COMMENT = 'save_feedback_comment',
  DELETE_FEEDBACK_COMMENT = 'delete_feedback_comment',
  NOTIFICATION_MENU_CLICKED = 'notification_menu_clicked',
  NOTIFICATION_LIST_CLICKED = 'notification_list_clicked',
  EDIT_DURATION_BUTTON_CLICKED = 'edit_duration_button_clicked',
  EDIT_DURATION_SAVED = 'edit_duration_saved',
}
export type ConditionDataType =
  | 'mood'
  | 'fitness'
  | 'sleep duration'
  | 'sleep quality'
  | 'condition'
  | 'fatigue'
  | 'stress'
export type WorkloadDataType =
  | 'workload'
  | 'workload duration'
  | 'workload satisfaction'
  | 'workload intensity'
export type AcwrDataType = 'acwr' | 'acute & chronic workload' | 'monotony'
export type InjuryDataType =
  | 'soreness 1'
  | 'soreness 2'
  | 'soreness 3'
  | 'soreness 4'
  | 'soreness 5'

export type WorkloadTableDataType = 'workload' | 'intensity' | 'duration' | 'satisfaction'

export type DataType = ConditionDataType | WorkloadDataType | AcwrDataType | InjuryDataType
export type TableColumnType = 'name' | Exclude<DataType, InjuryDataType>
export type DateType = 'date picker' | 'arrow' | 'today'
export const CATEGORY_TYPE: Record<string, DataType> = {
  injury_3: 'soreness 3',
  injury_4: 'soreness 4',
  injury_5: 'soreness 5',
  workload_acxr: 'acwr',
  workload_ax_cx: 'acute & chronic workload',
  workload_axd: 'monotony',
}
export const TABLE_COLUMN_TYPE: Record<string, TableColumnType> = {
  name: 'name',
  muscle: 'fitness',
  mood: 'mood',
  fatigue: 'fatigue',
  stress: 'stress',
  condition: 'condition',
  sleep_duration: 'sleep duration',
  sleep_quality: 'sleep quality',
  workload: 'workload',
  workload_intensity: 'workload intensity',
  workload_duration: 'workload duration',
  workload_satisfaction: 'workload satisfaction',
}

export const REPORT_CHART_CATEGORY = {
  condition_condition: 'condition',
  condition_fatigue: 'fatigue',
  condition_stress: 'stress',
  condition_muscle: 'muscle',
  condition_mood: 'mood',
  condition_duration: 'sleep_duration',
  condition_quality: 'sleep_quality',
  workload_workload: 'workload',
  workload_intensity: 'intensity',
  workload_duration: 'duration',
  workload_satisfaction: 'satisfaction',
}

export enum InjuryType {
  current = 'current',
  past = 'past',
}

export type ExcludedFeedbackType =
  | Exclude<FeedbackType, FeedbackType.WORKLOAD_MEMO>
  | Exclude<FeedbackType, FeedbackType.WORKLOAD_MEMO | FeedbackType.REQUEST>
export const EXCLUDED_FEEDBACK_TYPE: Record<ExcludedFeedbackType, string> = {
  NORMAL: 'normal',
  CONDITION_NOT_GOOD: 'bad_condition',
  CONDITION_PLUMMET: 'plummeted_condition',
  WORKLOAD_RISK_AT_INJURY: 'injury_risk',
  SORENESS_SEVERE: 'serious_soreness',
  REQUEST: 'feedback_request',
}

export type AnalyticsEventParams = {
  // team management
  [AnalyticsEventType.TEAM_DELETION]: EmptyObject
  [AnalyticsEventType.KICK_OUT]: EmptyObject
  // onboarding
  [AnalyticsEventType.LOGIN]: EmptyObject
  [AnalyticsEventType.FIRST_LOGIN]: EmptyObject
  [AnalyticsEventType.LOGOUT]: EmptyObject
  [AnalyticsEventType.REGISTRATION_ACCOUNT_CREATION]: EmptyObject
  [AnalyticsEventType.REGISTRATION_TEAM_CREATION]: EmptyObject
  [AnalyticsEventType.REGISTRATION_TEAM_JOIN]: EmptyObject
  [AnalyticsEventType.TUTORIAL_COMPLETION_ONBOARDING]: EmptyObject
  [AnalyticsEventType.COPY_TEAMCODE]: {
    conversion: string
  }
  [AnalyticsEventType.SORTING_CLICKED_FOR_TABLE]: {
    sorted_by: TableColumnType
  }
  [AnalyticsEventType.ACCEPT_INVITATION]: EmptyObject
  [AnalyticsEventType.REFUSAL_INVITATION]: EmptyObject
  // new-dashboard
  [AnalyticsEventType.CONDITION_ALARM_CLICKED]: {
    conversion: string
    all_selected: boolean
  }
  [AnalyticsEventType.WORKOUT_ALARM_CLICKED]: {
    conversion: string
    all_selected: boolean
  }
  [AnalyticsEventType.NEWDASHBOARD_TAB_CLICKED]: {
    sorted_by: string
  }
  [AnalyticsEventType.CONDITION_ALARM_LIST_CLICKED]: {
    type: string
  }
  [AnalyticsEventType.WORKOUT_ALARM_LIST_CLICKED]: {
    type: string
  }
  [AnalyticsEventType.VIEW_TEAM_EVENT_ONLY]: {
    conversion: string
  }
  [AnalyticsEventType.CLICK_GROUP_DATA]: {
    conversion: string
  }
  // dashboard
  [AnalyticsEventType.DASHBOARD_CONDITION_CHECK_ALARM_CLICKED]: EmptyObject
  [AnalyticsEventType.DASHBOARD_WORKLOAD_CHECK_ALARM_CLICKED]: EmptyObject
  [AnalyticsEventType.DASHBOARD_DATE_CHANGING_CLICKED]: {
    type: DateType
    view_date_range: number
  }
  [AnalyticsEventType.DASHBOARD_INJURED_PLAYER_CLICKED]: EmptyObject
  [AnalyticsEventType.OPEN_DATA_POPOVER]: {
    type: string
  }
  // monitoring
  [AnalyticsEventType.MONITORING_DATE_CHANGING_CLICKED]: {
    type: DateType
    view_date_range: number
  }
  [AnalyticsEventType.MONITORING_CATEGORY_DATA_INDEX]: {
    sorted_by: DataType
  }
  // group
  [AnalyticsEventType.GROUP_MAKING]: {
    the_number_of_players: number
  }
  [AnalyticsEventType.GROUP_CLICKED]: {
    group: number
  }
  [AnalyticsEventType.GROUP_MODIFICATION]: {
    the_number_of_players: number
  }
  [AnalyticsEventType.GROUP_DELETION]: EmptyObject
  // schedule
  [AnalyticsEventType.SCHEDULE_CREATION]: {
    schedule_type: EventType
    conversion: string
  }
  [AnalyticsEventType.SCHEDULE_MODIFICATION]: EmptyObject
  [AnalyticsEventType.SCHEDULE_DELETION]: EmptyObject
  [AnalyticsEventType.SCHEDULED_WORKLOAD_NOTIFICATION]: EmptyObject
  [AnalyticsEventType.REPORT_CLICKED]: {
    schedule_type: EventType
    conversion: string
  }
  [AnalyticsEventType.REPORT_RESULT_SAVED]: {
    schedule_type: EventType
  }
  [AnalyticsEventType.REPORT_LINEUP_SAVED]: {
    schedule_type: EventType
    conversion: string
  }
  [AnalyticsEventType.REPORT_RECORDCOMMENT_SAVED]: {
    schedule_type: EventType
  }
  [AnalyticsEventType.REPORT_MONITORING_CATEGORY_DATA_INDEX]: {
    schedule_type: EventType
    data_category: string
  }
  [AnalyticsEventType.REPORT_DATAVIEW_CLICKED]: {
    schedule_type: EventType
  }
  // insight
  [AnalyticsEventType.INSIGHT_DATE_CHANGING_CLICKED]: {
    type: 'today' | 'recent 7days' | 'recent 28days'
  }
  // players
  [AnalyticsEventType.STARRED_ADDITION]: EmptyObject
  [AnalyticsEventType.STARRED_CANCELLATION]: EmptyObject
  [AnalyticsEventType.CONDITION_CLICKED]: EmptyObject
  [AnalyticsEventType.WORKLOAD_CLICKED]: EmptyObject
  [AnalyticsEventType.ACWR_CLICKED]: EmptyObject
  [AnalyticsEventType.BODY_CLICKED]: EmptyObject
  [AnalyticsEventType.INJURY_CLICKED]: EmptyObject
  [AnalyticsEventType.REGISTER_INJURY]: {
    injury_type: InjuryType
    file_attached: boolean
    conversion: string
  }
  [AnalyticsEventType.DELETE_INJURY]: {
    injury_type: InjuryType
  }
  // profile
  [AnalyticsEventType.EDIT_PROFILE_PICTURE]: EmptyObject
  [AnalyticsEventType.EDIT_PROFILE]: EmptyObject
  // blog
  [AnalyticsEventType.MAIN_TAG_CLICKED]: {
    teamId_name: string
    sorted_by: string
  }
  [AnalyticsEventType.SUB_TAG_CLICKED]: {
    teamId_name: string
    sorted_by: string
  }
  // payment
  [AnalyticsEventType.PLAN_SELECTED]: {
    plan: string
  }
  [AnalyticsEventType.PERSONS_SELECTED]: {
    persons: string
  }
  [AnalyticsEventType.PAYMENT_COMPLETED]: EmptyObject
  [AnalyticsEventType.PLAN_CANCEL]: EmptyObject
  [AnalyticsEventType.PLAN_SUBSCRIPTION_BANNER_CLICKED]: EmptyObject
  // uncategorized
  [AnalyticsEventType.CHANNEL_TALK_CLICKED]: EmptyObject
  // player modal opened
  [AnalyticsEventType.PLAYER_PAGE_CLICKED]: EmptyObject
  // training point
  [AnalyticsEventType.REPORT_TRAINING_TRAININGPOINT_SAVED]: EmptyObject
  [AnalyticsEventType.REPORT_TRAINING_SPECIALNOTES_SAVED]: EmptyObject
  [AnalyticsEventType.REPORT_TRAINING_PLAYERMEMO_CLICKED]: EmptyObject
  [AnalyticsEventType.REPORT_EXPORT_CLICK]: {
    schedule_type: string
  }
  [AnalyticsEventType.REPORT_EXPORT_SAVE]: {
    schedule_type: string
    export_type: ReportFileType
  }
  [AnalyticsEventType.ATTENDANCE_ALARM_CLICKED]: {
    conversion: string
    all_selected: boolean
  }
  // feedback
  [AnalyticsEventType.REGISTER_FEEDBACK_CLICKED]: {
    conversion: 'FAB' | 'squad' | 'newdashboard'
  }
  [AnalyticsEventType.REGISTER_FEEDBACK]: {
    type: string
    conversion: string
  }
  [AnalyticsEventType.EDIT_FEEDBACK]: {
    type: string
  }
  [AnalyticsEventType.DELETE_FEEDBACK]: {
    type: string
  }
  [AnalyticsEventType.VIEW_FEEDBACK]: {
    type: string
    conversion: 'squad' | 'notification' | 'newdashboard'
  }
  [AnalyticsEventType.SAVE_FEEDBACK_COMMENT]: {
    type: string
  }
  [AnalyticsEventType.DELETE_FEEDBACK_COMMENT]: {
    type: string
  }
  [AnalyticsEventType.NOTIFICATION_MENU_CLICKED]: EmptyObject
  [AnalyticsEventType.EDIT_DURATION_BUTTON_CLICKED]: {
    conversion: 'training' | 'match' | 'practice_match'
  }
  [AnalyticsEventType.EDIT_DURATION_SAVED]: {
    conversion: 'training' | 'match' | 'practice_match'
    all_selected: boolean
  }
}

export enum PageEventName {
  NEWDASHBOARD = 'pv_newdashboard',
  DASHBOARD = 'pv_dashboard',
  MONITORING = 'pv_monitoring',
  TEAM_MANAGE = 'pv_topnavigation',
  SCHEDULER = 'pv_schedule',
  INSIGHT = 'pv_insight',
  PLAYERS = 'pv_players',
  BLOG = 'pv_blog',
  BLOG_CONTENT = 'pv_blog_contents',
  BLOG_SEARCH = 'pv_blog_search',
  BLOG_ONBOARDING = 'pv_blog_onboarding',
  PLAN_SELECT = 'pv_plan_select',
  PERSONS_SELECT = 'pv_persons_select',
  PAYMENT_MANAGEMENT = 'pv_payment_management',
}
