import { UPDATE_DATA_RANGE } from './constant'
import { moment } from './libs'

export const MINUTES_PER_HOUR = 60

export const setTime = (
  destDate: Date | string | undefined,
  srcDate: Date | string | undefined,
) => {
  const momentSrcDate = moment(srcDate)
  return moment(destDate)
    .clone()
    .set({
      hours: momentSrcDate.get('hours'),
      minutes: momentSrcDate.get('minutes'),
      seconds: momentSrcDate.get('seconds'),
      milliseconds: momentSrcDate.get('milliseconds'),
    })
}

export const toTime = (date: Date | undefined) => {
  return date ? moment(date).format('LT') : undefined
}
export const toTimetoISO = (date: Date | undefined) => {
  return date ? moment(date).format('HH:mm') : undefined
}
export const getTimeDiff = (from: Date | undefined, to: Date | undefined) => {
  const hours = moment(to).diff(from, 'hours')
  const minutes = moment(to).diff(from, 'minute')
  if (minutes > 0) {
    return {
      hours: hours,
      minutes: minutes - hours * 60,
    }
  }

  return
}

export const isDateBeforeUpdateRange = (date?: string) => {
  const today = moment()

  return today.diff(date, 'days') > UPDATE_DATA_RANGE
}

export const isPastDate = (date?: string | Date) => {
  return moment().isAfter(date)
}

export const formatPaddingDate = (date?: number) => {
  if (!date) return

  if (date < 10) {
    return `0${date}`
  }

  return date
}

export const createUtcDate = () => {
  return new Date(
    Date.UTC(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      new Date().getHours(),
      new Date().getMinutes(),
      new Date().getSeconds(),
      new Date().getMilliseconds(),
    ),
  )
}
