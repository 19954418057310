import { useCallback, useMemo, useState } from 'react'

import {
  QualificationType,
  SubscriptionStatusEnum,
  useRefundMutation,
  useRequestBillingTokenMutation,
} from '@plco-pro/graphqls/react.generated'
import { useAuthorization } from '@plco-pro/hooks/authorization'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useIamport } from '@plco-pro/hooks/iamport'
import { useToast } from '@plco-pro/hooks/toast'
import { PaymentData, RequestPayResponse } from '@plco-pro/utils/libs/iamport.types'

const PG = 'danal_tpay'
const PAY_METHOD = 'card'
const PAY_AMOUNT = 100

type requestBillingTokenInput = {
  teamId: string
}

const getPayData = (
  customerUid: string,
  paymentToken: string,
  input: requestBillingTokenInput,
  name: string,
): PaymentData => {
  return {
    customer_uid: customerUid,
    pg: PG,
    pay_method: PAY_METHOD,
    merchant_uid: paymentToken,
    amount: PAY_AMOUNT,
    name,
  }
}

export const usePlan = () => {
  const { showToast } = useToast()
  const { formatMessage: f } = useI18n()
  const { requestPay } = useIamport()
  const { hasValidSubscription, hasValidLicense } = useAuthorization()

  const [billingToken, setBillingToken] = useState<RequestPayResponse>()

  const planQualificationType: QualificationType | null = useMemo(() => {
    if (hasValidSubscription === null) {
      return QualificationType.LICENSE
    } else if (hasValidLicense === null) {
      return QualificationType.SUBSCRIPTION
    }

    return null
  }, [hasValidLicense, hasValidSubscription])

  const [refundMutation] = useRefundMutation()
  const [requestBillingTokenMutation] = useRequestBillingTokenMutation()

  const requestPayCallback = useCallback(
    (response: RequestPayResponse) => {
      if (!response.success) {
        showToast(f({ id: 'plan.incomplete-error' }), {
          status: 'error',
        })
        return
      }

      setBillingToken(response)

      if (response.imp_uid) {
        refundMutation({
          variables: {
            input: {
              impUid: response.imp_uid,
            },
          },
        })
      }
    },
    [f, showToast, refundMutation],
  )

  const requestBillingToken = useCallback(
    (input: requestBillingTokenInput) => {
      try {
        requestBillingTokenMutation({
          variables: {
            input: {
              identityToken: input.teamId,
            },
          },
          onCompleted: (data) => {
            const { customerUid, paymentToken } = data.requestBillingToken
            const name = f({ id: 'plan.change.card.product.name' })
            const payData = getPayData(customerUid, paymentToken, input, name)

            requestPay(payData, requestPayCallback)
          },
          onError: () => {
            showToast(f({ id: 'plan.server-error' }), {
              status: 'error',
            })
          },
        })
      } catch {
        showToast(f({ id: 'plan.server-error' }), {
          status: 'error',
        })
      }
    },
    [requestPayCallback, f, requestBillingTokenMutation, requestPay, showToast],
  )

  const formatCardInfo = useCallback((name, number) => {
    return `${name?.slice(0, -2)} ${number?.slice(-8)}`
  }, [])

  const validName = useCallback((name: string) => !(name.length < 2 || name.length > 50), [])

  const validEmail = useCallback((email: string) => {
    const regExp = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.[a-zA-Z]{2,4}$/
    return regExp.test(email)
  }, [])

  const validPhone = useCallback((phone: string) => {
    const regExp = /^(01[016789]{1})[0-9]{4}[0-9]{4}$/
    return regExp.test(phone)
  }, [])

  const hasActivateSubscription = useCallback((subscription): boolean | null => {
    const { status, isSubscripting } = subscription
    return status === SubscriptionStatusEnum.ACTIVATE && isSubscripting
  }, [])

  const hasResumableSubscription = useCallback((subscription): boolean | null => {
    const { status, isSubscripting } = subscription
    return status === SubscriptionStatusEnum.ACTIVATE && !isSubscripting
  }, [])

  const hasGracePeriodSubscription = useCallback((subscription): boolean | null => {
    const { status, isSubscripting } = subscription
    return status === SubscriptionStatusEnum.GRACE_PERIOD && isSubscripting
  }, [])

  const hasInactivateSubscription = useCallback((subscription): boolean | null => {
    const { status, isSubscripting } = subscription
    return status === SubscriptionStatusEnum.INACTIVATE && !isSubscripting
  }, [])

  return {
    billingToken,
    requestBillingToken,
    formatCardInfo,
    validName,
    validEmail,
    validPhone,
    planQualificationType,
    hasActivateSubscription,
    hasResumableSubscription,
    hasGracePeriodSubscription,
    hasInactivateSubscription,
  }
}
