import { Fragment, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useRouter } from 'next/router'

import { Flex, Icon } from '@plco-pro/components/atoms'
import { DialogFeedback } from '@plco-pro/components/organisms/dialog-feedback/dialog-feedback'
import { ReactPortal } from '@plco-pro/components/react-portal/react-portal'
import { useI18n } from '@plco-pro/hooks'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useAuthorization } from '@plco-pro/hooks/authorization'
import { useChannelTalk } from '@plco-pro/hooks/channel-talk'
import { AnalyticsEventType } from '@plco-pro/utils/libs'

import { Tooltip } from '../tooltip'

const TOOLTIP_ID = 'tooltip-feedback-add-fab'

export const FeedbackFab = observer(() => {
  const { formatMessage: f } = useI18n()
  const { isOpenedMessenger: isOpenMessenger } = useChannelTalk()
  const { isObserver, hasValidPlan, hasValidLicense } = useAuthorization()

  const { trackEvent } = useAnalytics()
  const router = useRouter()

  const [isOpenedFeedbackDialog, setIsOpenedFeedbackDialog] = useState(false)

  const onClickFloatingButton = () => {
    trackEvent(AnalyticsEventType.REGISTER_FEEDBACK_CLICKED, { conversion: 'FAB' })
    setIsOpenedFeedbackDialog(true)
  }

  const onCloseFeedbackDialog = () => {
    setIsOpenedFeedbackDialog(false)
  }

  const showFab = useMemo(() => {
    if (
      isOpenMessenger ||
      isObserver ||
      new RegExp('/plan/*').test(router.pathname) ||
      new RegExp('/teams').test(router.pathname) ||
      (!hasValidPlan && !hasValidLicense)
    ) {
      return false
    }

    return true
  }, [hasValidLicense, hasValidPlan, isOpenMessenger, isObserver, router.pathname])

  if (!showFab) {
    return <Fragment />
  }

  return (
    <Fragment>
      <ReactPortal disableOverflowHidden={true}>
        <Flex
          data-tooltip-id={TOOLTIP_ID}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 900,
            position: 'fixed',
            bottom: 3,
            right: ['12px', 3],
            width: ['40px', '56px'],
            height: ['40px', '56px'],
            p: '4px',
            border: '1px solid',
            borderColor: 'grey-50',
            borderRadius: 50,
            boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            bg: 'indigo-500',
            ':hover': {
              bg: 'indigo-600',
            },
            ':active': {
              bg: 'indigo-700',
            },
          }}
          onClick={onClickFloatingButton}
        >
          <Icon src={'/images/ic_chat_bubble_outline_pen_edit.svg'} size={24} color={'white-500'} />
        </Flex>

        <Tooltip id={TOOLTIP_ID} content={f({ id: 'fab.send-feedback' })} place="left" />
      </ReactPortal>

      {isOpenedFeedbackDialog && (
        <DialogFeedback
          isOpen={isOpenedFeedbackDialog}
          onClose={onCloseFeedbackDialog}
          entry={'fab'}
        />
      )}
    </Fragment>
  )
})
