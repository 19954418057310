import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import { useAuthorization } from '@plco-pro/hooks/authorization'
import { useViewer } from '@plco-pro/hooks/viewer/viewer'

export type AuthorizationContext = {
  authorized: boolean | undefined
}

export const AuthorizationContext = createContext<AuthorizationContext>({
  authorized: false,
})

export const useAuthorizationContext = () => {
  return useContext(AuthorizationContext)
}

const AuthorizationProvider: FunctionComponent = (props) => {
  const router = useRouter()
  const { hasRole, hasAccess, hasPlanAccess } = useAuthorization()
  const { viewer, loading, teamAuthority } = useViewer()

  const userId = viewer?.id

  const [authorized, setAuthorized] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    const isNotFoundPage = router.pathname.match('/404') !== null
    if (isNotFoundPage) {
      // skip check for not found page
      setAuthorized(true)
    } else if (!userId || !teamAuthority) {
      // skip check for unauthenticated users
      setAuthorized(true)
    } else if (hasRole(teamAuthority)) {
      // check for access
      setAuthorized(hasAccess(router.asPath))
      setAuthorized(hasPlanAccess(router.asPath))
    }
  }, [hasRole, hasAccess, hasPlanAccess, teamAuthority, userId, router.asPath, router.pathname])

  const value = {
    authorized,
  }

  return (
    <AuthorizationContext.Provider value={value}>
      {loading ? null : props.children}
    </AuthorizationContext.Provider>
  )
}

export default AuthorizationProvider
