import { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from 'react'

import { downloadFile } from '@common/utils/file'
import _debounce from 'lodash/debounce'

import {
  Injury,
  InjuryHistoryStatus,
  InjuryReturnStatus,
  InjuryStatus,
  SportsTeamAuthority,
  UserCurrentInjuryListDocument,
  UserPastInjuryListDocument,
  useDeleteInjuryMutation,
  useUpdateInjuryMutation,
  DashboardTeamCurrentInjuryUserListDocument,
} from '@plco-pro/graphqls/react.generated'
import { useI18n, useNationality, useToast, useViewer } from '@plco-pro/hooks'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useMoment } from '@plco-pro/hooks/moment'
import { getScreenSize } from '@plco-pro/providers/responsive'
import { AnalyticsEventType, InjuryType } from '@plco-pro/utils/libs'

import { Divider, Flex, Icon, Text } from '../atoms'
import { IconButton } from '../molecules'
import { ChipInjury } from '../molecules/chip-injury'
import { SectionInjuryStatus } from '../molecules/section-injury-status'
import { DialogInjuryDelete } from './dialog-injury-delete'
import { Modal } from './modal'

interface InjuryFieldsProps {
  title: string
  data?: string | null
}

const InjuryFields = ({ title, data }: InjuryFieldsProps) => {
  if (!data) {
    return <Fragment />
  }

  return (
    <Flex sx={{ flexDirection: 'column', gap: 1, width: '100%' }}>
      <Text color={'text-secondary'}>{title}</Text>
      <Text variant={'s2'} sx={{ fontWeight: '400' }}>
        {data}
      </Text>
    </Flex>
  )
}

interface Props {
  open: boolean
  onClose: () => void
  injury?: Injury
  onModify: () => void
  teamId: string
  userId: string
  setInjury: Dispatch<SetStateAction<Injury | undefined>>
}

export const DialogInjuryDetail = ({
  open,
  onClose,
  injury,
  onModify,
  teamId,
  userId,
  setInjury,
}: Props) => {
  const { formatMessage } = useI18n()
  const { mdAndDown } = getScreenSize(false)
  const { showToast } = useToast()
  const moment = useMoment()
  const { teamAuthority } = useViewer()
  const { trackEvent } = useAnalytics()
  const nationality = useNationality()

  const [isOpenDeleteInjuryDialog, setIsOpenDeleteInjuryDialog] = useState(false)
  const [injuryData, setInjuryData] = useState<Injury>()

  const [updateInjuryMutation] = useUpdateInjuryMutation()

  const [deleteInjuryMutation] = useDeleteInjuryMutation({
    onCompleted: () => {
      trackEvent(AnalyticsEventType.DELETE_INJURY, {
        injury_type:
          injuryData?.status === InjuryStatus.IN_PROGRESS ? InjuryType.current : InjuryType.past,
      })

      showToast(formatMessage({ id: 'toast.injury-delete.success' }), { status: 'success' })

      closeDeleteInjuryDialog()
      onClose()
    },
    refetchQueries: [
      {
        query: DashboardTeamCurrentInjuryUserListDocument,
        variables: {
          input: {
            nationality,
          },
          teamId: teamId as string,
        },
      },
      {
        query: UserCurrentInjuryListDocument,
        variables: { input: { nationality }, teamId: teamId as string, userId },
      },
      {
        query: UserPastInjuryListDocument,
        variables: { input: { nationality }, teamId: teamId as string, userId },
      },
    ],
  })

  useEffect(() => {
    setInjuryData(injury)
  }, [injury])

  const onClickInjuryStatusToggle = useMemo(
    () =>
      _debounce(
        () => {
          if (!injuryData?.status) {
            return
          }

          const nextInjuryStatus =
            injuryData?.status === InjuryStatus.END ? InjuryStatus.IN_PROGRESS : InjuryStatus.END

          const maintainFileIdList = injuryData?.fileList.map((file) => file.id) || []
          const {
            return: { __typename: _returnTypename, ...returnData },
            __typename: _injuryTypename,
            fileList: injuryFileList,
            ...newInjuryData
          } = injuryData

          const changedStatusInjuryData = {
            ...injuryData,
            status: nextInjuryStatus,
          }

          updateInjuryMutation({
            variables: {
              multilingualTextInput: {
                nationality,
              },
              input: {
                teamId,
                userId,
                maintainFileIdList,
                ...newInjuryData,
                bodyPart: newInjuryData.bodyPart?.id,
                return: { ...returnData },
                status: nextInjuryStatus,
              },
            },
            optimisticResponse: {
              updateInjury: {
                ...changedStatusInjuryData,
                __typename: 'Injury',
              },
              __typename: 'Mutation',
            },
            onCompleted: () => {
              setInjury(changedStatusInjuryData)

              trackEvent(AnalyticsEventType.REGISTER_INJURY, {
                injury_type:
                  nextInjuryStatus === InjuryStatus.IN_PROGRESS
                    ? InjuryType.current
                    : InjuryType.past,
                file_attached: !!injuryFileList.length,
                conversion: 'squad',
              })

              if (nextInjuryStatus === InjuryStatus.END) {
                showToast(formatMessage({ id: 'toast.injury-update.past.success' }), {
                  status: 'success',
                })

                return
              }

              showToast(formatMessage({ id: 'toast.injury-update.current.success' }), {
                status: 'success',
              })
            },
            refetchQueries: [
              {
                query: UserCurrentInjuryListDocument,
                variables: { teamId, userId, input: { nationality } },
              },
              {
                query: UserPastInjuryListDocument,
                variables: { teamId, userId, input: { nationality } },
              },
              {
                query: DashboardTeamCurrentInjuryUserListDocument,
                variables: {
                  teamId,
                  input: {
                    nationality,
                  },
                },
              },
            ],
          })
        },
        500,
        { leading: true },
      ),
    [
      formatMessage,
      injuryData,
      setInjury,
      showToast,
      teamId,
      trackEvent,
      updateInjuryMutation,
      userId,
      nationality,
    ],
  )

  const onConfirmDeleteInjury = () => {
    if (!injuryData?.id || !teamId) {
      return
    }

    deleteInjuryMutation({
      variables: {
        input: {
          teamId,
          id: injuryData.id,
        },
      },
    })
  }

  const closeDeleteInjuryDialog = () => {
    setIsOpenDeleteInjuryDialog(false)
  }

  const disableButtonForObserver = !!teamAuthority && teamAuthority === SportsTeamAuthority.OBSERVER

  if (!injuryData) {
    return <Fragment />
  }

  return (
    <Fragment>
      <Modal
        headerProps={{
          title: formatMessage({ id: 'player.modal-injury.detail.header' }),
        }}
        width={'modal.width.md'}
        maxHeight={'modal.height.lg'}
        open={open}
        onClose={onClose}
        isFullScreen={mdAndDown}
        contentPaddingDisable={true}
        focusTrapped={false}
      >
        <Flex sx={{ flexDirection: 'column', mx: 3, pt: 3, gap: 3, py: 4 }}>
          <Flex sx={{ gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
            <Flex sx={{ flexDirection: 'column', gap: '4px' }}>
              <Flex>
                <ChipInjury injuryStatus={injuryData?.status} />
              </Flex>
              <Text variant={'h4'} sx={{ fontWeight: '700' }}>
                {injuryData?.bodyPart ? injuryData?.bodyPart.value : injuryData?.reason}
              </Text>
            </Flex>
            <Flex sx={{ gap: 1 }}>
              <IconButton
                src={'/images/edit-pencil.svg'}
                sx={{ width: '40px', height: '40px', flexShrink: 0 }}
                onClick={onModify}
                disabled={disableButtonForObserver}
                iconSize={'24px'}
              />
              <IconButton
                src={'/images/ic-delete-outline.svg'}
                sx={{ width: '40px', height: '40px', flexShrink: 0 }}
                onClick={() => setIsOpenDeleteInjuryDialog(true)}
                disabled={disableButtonForObserver}
                iconSize={'24px'}
              />
            </Flex>
          </Flex>
          <InjuryFields
            title={formatMessage({ id: 'player.modal-injury.detail-selection.date.title' })}
            data={
              `${moment(injuryData?.occurDate).format('YYYY.MM.DD')}` +
              (injuryData?.historyStatus === InjuryHistoryStatus.RELAPSE
                ? ` (${formatMessage({ id: 'player.modal-injury.detail-selection.relapse.text' })})`
                : '')
            }
          />
          <InjuryFields
            title={formatMessage({ id: 'player.modal-injury.detail-selection.cause.title' })}
            data={injuryData?.reason}
          />
          <InjuryFields
            title={formatMessage({ id: 'player.modal-injury.detail-selection.diagnosis.title' })}
            data={injuryData?.diagnosis}
          />
          <InjuryFields
            title={formatMessage({
              id: 'player.modal-injury.detail-selection.medical-record.title',
            })}
            data={injuryData?.medicalRecord}
          />
          <InjuryFields
            title={formatMessage({ id: 'player.modal-injury.detail-selection.return-date.title' })}
            data={
              injuryData?.return.status === InjuryReturnStatus.UNDECIDED
                ? formatMessage({ id: 'player.modal-injury.detail-selection.return-date.undecide' })
                : moment(injuryData?.return.date).format('YYYY.MM.DD')
            }
          />
          <InjuryFields
            title={formatMessage({ id: 'player.modal-injury.detail-selection.return-info.title' })}
            data={injuryData?.return?.info}
          />
          <Flex sx={{ gap: 1, flexDirection: 'column' }}>
            {(injuryData?.fileList || []).map((file, index) => (
              <Flex
                key={index}
                onClick={() => downloadFile(file.url, file.name)}
                sx={{
                  width: '100%',
                  gap: 3,
                  py: 1,
                  px: 2,
                  justifyContent: 'space-between',
                  bg: 'grey-50',
                  borderRadius: '8px',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    bg: 'grey-100',
                  },
                  '&:active': {
                    bg: 'grey-200',
                  },
                }}
              >
                <Flex>
                  <Text sx={{ mr: 1, flexShrink: 0 }}>{'📎'}</Text>
                  <Text ellipsis>{`${file?.name}`}</Text>
                </Flex>
                <Flex sx={{ flexShrink: 0 }}>
                  <Icon src={'/images/download-file.svg'} color={'primary-500'} />
                </Flex>
              </Flex>
            ))}
          </Flex>
          <Divider color={'grey-100'} />
          <SectionInjuryStatus
            onToggle={onClickInjuryStatusToggle}
            active={injuryData?.status === InjuryStatus.END}
            disabled={disableButtonForObserver}
          />
        </Flex>
      </Modal>
      <DialogInjuryDelete
        open={isOpenDeleteInjuryDialog}
        onClose={() => setIsOpenDeleteInjuryDialog(false)}
        onDelete={onConfirmDeleteInjury}
      />
    </Fragment>
  )
}
