import {
  SportsTeamInvitationRequestStatus,
  type NavigationViewerQuery,
} from '@plco-pro/graphqls/react.generated'

export const getTeamId = (data: NavigationViewerQuery) => {
  const viewer = data?.sports?.viewer

  const invitationRequestsViewer = viewer?.invitationRequests?.find(
    (invitationRequest) =>
      invitationRequest?.user?.id === viewer?.id &&
      invitationRequest.status !== SportsTeamInvitationRequestStatus.LEFT &&
      invitationRequest.status !== SportsTeamInvitationRequestStatus.REJECTED,
  )

  return viewer?.teamMaps[0]?.team?.id || invitationRequestsViewer?.team?.id
}

export const getTeams = (data: NavigationViewerQuery) => {
  const viewer = data?.sports?.viewer

  const teamMapsTeams = viewer?.teamMaps.map((teamMap) => teamMap.team)
  const hyperTeam = teamMapsTeams?.filter((teamMap) => teamMap.subscriptionItem?.planId === 'HYPER')
  const invitationRequestsTeams = viewer?.invitationRequests.map(
    (invitationRequest) => invitationRequest?.team,
  )

  // use data from either teamMaps or invitationRequests
  return [...(hyperTeam || []), ...(teamMapsTeams || []), ...(invitationRequestsTeams || [])]
}
