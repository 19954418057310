import packageJson from 'plco-pro/package.json'

import { EventInviteeStatus } from '@plco-pro/graphqls/react.generated'

export const DRAWER_ANIMATION_DURATION = 500

export const LAYOUT_MAIN_CONTENT_PADDING = [2, 4, 4, 4]

export const SIDE_NAVIGATION_WIDTH = 268

export const PLCO_COACH_VERSION = packageJson.version

// MONITORING
export const MONITORING_TABLE_LIMIT = 20

// TRAINING REPORT

export type StatusType =
  | {
      value: string
      type: null
    }
  | {
      value: string
      type: EventInviteeStatus
    }

export const EVENT_INVITEE_STATUS = [
  { value: 'all', type: null },
  { value: 'attend', type: EventInviteeStatus.PARTICIPATION },
  { value: 'injured', type: EventInviteeStatus.ABSENCE_INJURY },
  { value: 'etc', type: EventInviteeStatus.ABSENCE_ETC },
  { value: 'unchecked', type: EventInviteeStatus.NOT_CHECK },
]

export const ROUTE_PATHS = {
  DASHBOARD: '/dashboard',
  TEAM_CHART: '/teamchart',
  MONITORING: '/monitoring',
  INSIGHT: '/insight',
  PLAYERS: '/players',
  EVENT: '/event',
  BLOG: '/blog',
  PROFILE: '/profile',
  TEAMS: '/teams',
  PLAN: '/plan/billing',
  PAY: '/plan/pay',
} as const

export const WITHDRAWN_USER = 'withdrawnUser'
