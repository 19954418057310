import React, { useCallback, useMemo } from 'react'

import { useRouter } from 'next/router'

import { Box } from '@plco-pro/components/atoms/box'
import { Button } from '@plco-pro/components/atoms/button'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Image } from '@plco-pro/components/atoms/image'
import { Text } from '@plco-pro/components/atoms/text'
import { QualificationType } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import { usePlan } from '@plco-pro/hooks/plan'
import { useResponsiveContext } from '@plco-pro/providers/responsive'
import { useStore } from '@plco-pro/stores'

export type PlanExpirationProps = {}

export const PlanExpiration: React.FunctionComponent<PlanExpirationProps> = () => {
  const router = useRouter()
  const { formatMessage: f } = useI18n()
  const { smOnly } = useResponsiveContext()
  const { planQualificationType } = usePlan()

  const {
    navigation: { teamSubscriptionIsFreeTrial },
  } = useStore()

  const handleMoveToPlanPage = useCallback(() => router.push('/plan/pay'), [router])

  const items = useMemo(() => {
    if (teamSubscriptionIsFreeTrial) {
      return {
        title: 'plan.free-trial.expiration.title',
        onClick: handleMoveToPlanPage,
      }
    } else if (planQualificationType === QualificationType.LICENSE) {
      return {
        title: 'plan.expiration.title',
        onClick: handleMoveToPlanPage,
      }
    } else {
      return {
        title: 'plan.expiration.title',
        onClick: handleMoveToPlanPage,
      }
    }
  }, [teamSubscriptionIsFreeTrial, planQualificationType, handleMoveToPlanPage])

  return (
    <Flex sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <Flex sx={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}>
          <Image src={'/images/coco-fighting.png'} sx={{ width: [144, null, null, 112], mb: 3 }} />
          <Text variant={['s1', 'h4']} sx={{ mb: '4px' }}>
            {f({ id: items.title })}
          </Text>
          <Text variant={['p2', 'p1']} sx={{ whiteSpace: 'pre-line', textAlign: 'center', mb: 3 }}>
            {f({
              id: smOnly ? 'plan.expiration.subtitle.smOnly' : 'plan.expiration.subtitle.mdAndUp',
            })}
          </Text>
          <Button variant={'primary'} sx={{ minWidth: '128px', px: 2 }} onClick={items.onClick}>
            {f({ id: 'plan.billing.subscribe.plan' })}
          </Button>
        </Flex>
      </Box>
    </Flex>
  )
}
