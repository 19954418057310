import { Fragment, useCallback, useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import { DialogRegistFeedback } from '@plco-pro/components/organisms/dialog-feedback/dialog-regist-feedback'
import { DialogSelectFeedback } from '@plco-pro/components/organisms/dialog-feedback/dialog-select-feedback'
import { ModalCloseFeedback } from '@plco-pro/components/organisms/dialog-feedback/modal-close-feedback'
import {
  DialogPlayerSelect,
  PlayerSelectedType,
} from '@plco-pro/components/organisms/dialog-player-select'
import {
  FeedbackSuggestion,
  useFeedbackSuggestionListLazyQuery,
} from '@plco-pro/graphqls/react.generated'
import { useI18n, useToast, useViewer } from '@plco-pro/hooks'
import { LANGUAGE_MAP } from '@plco-pro/hooks/multilingual'
import { useStore } from '@plco-pro/stores'

interface Props {
  isOpen: boolean
  entry: 'squad' | 'fab' | 'newdashboard'
  player?: PlayerSelectedType
  onCompleted?: () => void
  onClose: () => void
}

export const DialogFeedback = ({ isOpen, entry, player, onCompleted, onClose }: Props) => {
  const { preference } = useStore()
  const { currentActiveTeam } = useViewer()
  const { formatMessage } = useI18n()
  const { push } = useRouter()
  const { showToast } = useToast()

  const [isOpenCloseModal, setIsOpenCloseModal] = useState(false)
  const [dialogStep, setDialogStep] = useState<number>()
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerSelectedType>()
  const [selectedFeedback, setSelectedFeedback] = useState<FeedbackSuggestion | null>()

  const language = preference.locale.language.split('-')[0] as keyof typeof LANGUAGE_MAP

  const [feedbackSuggestionListQuery, { data, loading }] = useFeedbackSuggestionListLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const user = data.sports.user

      if (!user) {
        showToast(formatMessage({ id: `ERROR.TEAM_USER_MAP_NOT_FOUND` }), { status: 'error' })
        push('/dashboard')
      }
    },
  })

  useEffect(() => {
    const step = entry === 'squad' ? 2 : 1

    if (entry === 'squad') {
      setSelectedPlayer(player)
    }

    setDialogStep(step)
  }, [entry, player, isOpen])

  useEffect(() => {
    const isSameUser = data?.sports.user?.id === selectedPlayer?.id

    if (dialogStep !== 2 || isSameUser || !selectedPlayer) {
      return
    }

    feedbackSuggestionListQuery({
      variables: {
        teamId: currentActiveTeam?.id as string,
        userId: selectedPlayer?.id as string,
        timezone: preference.locale.timezone,
        input: {
          nationality: `all_country_code__${LANGUAGE_MAP[language]}`,
        },
      },
    })
  }, [
    feedbackSuggestionListQuery,
    currentActiveTeam?.id,
    dialogStep,
    language,
    preference.locale.timezone,
    selectedPlayer,
    data,
  ])

  const feedbackSuggestionList = data?.sports.user?.feedbackSuggestionList

  const onPrev = () => {
    if (!dialogStep) {
      return
    }

    setDialogStep(dialogStep - 1)
  }

  const onChangePlayer = useCallback((player: PlayerSelectedType) => {
    setSelectedPlayer(player)
  }, [])

  const onSelectFeedback = useCallback((feedback?: FeedbackSuggestion | null) => {
    setSelectedFeedback(feedback)
  }, [])

  const onCloseDialog = () => {
    setSelectedPlayer(undefined)
    onClose()
  }

  const onCancelFeedback = () => {
    setIsOpenCloseModal(false)
    onCloseDialog()
  }

  return (
    <Fragment>
      {dialogStep === 1 && (
        <DialogPlayerSelect
          title={formatMessage({ id: 'fab.select-feedback-player' })}
          open={isOpen && dialogStep === 1}
          player={selectedPlayer}
          onClose={onCloseDialog}
          onNext={() => setDialogStep(2)}
          onChangePlayer={onChangePlayer}
        />
      )}

      {selectedPlayer && (
        <DialogSelectFeedback
          loading={loading}
          isOpen={isOpen && dialogStep === 2}
          player={selectedPlayer}
          feedbackSuggestionList={(feedbackSuggestionList as FeedbackSuggestion[]) || []}
          entry={entry}
          onClose={onCloseDialog}
          onPrev={onPrev}
          onNext={() => setDialogStep(3)}
          onSelectFeedback={onSelectFeedback}
        />
      )}

      {selectedPlayer && selectedFeedback !== undefined && (
        <DialogRegistFeedback
          entry={entry}
          isOpen={isOpen && dialogStep === 3}
          player={selectedPlayer}
          feedback={selectedFeedback}
          onCompleted={onCompleted}
          onClose={onCloseDialog}
          onCancel={() => setIsOpenCloseModal(true)}
          onPrev={onPrev}
        />
      )}

      <ModalCloseFeedback
        isOpen={isOpenCloseModal}
        onClose={() => setIsOpenCloseModal(false)}
        onCancel={onCancelFeedback}
      />
    </Fragment>
  )
}
